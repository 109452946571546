import { Card, Col, Row, Tabs, Typography } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { manifestService } from '../../../modules/nebula/services';
import { useLoader } from '../../../stores/use-loader';
import ManifestTenents from './manifest-tenents';

interface IManifest {
  productOf: string;
}

const Manifest: React.FunctionComponent<IManifest> = ({ productOf }) => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = React.useState<string>('0');
  const [serviceName, setServiceName] = React.useState<any>([]);

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = async () => {
    setLoading(true);
    const { data, errors } = await manifestService.getAllOperator();
    if (_.isEmpty(errors)) {
      let carrierTab = data?.operators
        ?.filter((items: any) => {
          return items.is_active && items.operator_name;
        })
        ?.slice(0, 5)
        ?.map((item: any) => {
          let obj = {
            name: item.operator_name,
            id: item.operator_id
          };
          return obj;
        });
      setServiceName(carrierTab);
    } else {
      displayErrorNotifications(errors);
    }
    // setLoading(false);
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4" gutter={12}>
            <Col xs={12} md={6}>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('mainfest')}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <Tabs defaultActiveKey="0" onChange={handleTabChange}>
              {/* type="card" */}
              {serviceName?.map((item: any, index: any) => {
                return (
                  <TabPane
                    tab={
                      <Typography.Text
                        className="font-inter text-base font-normal leading-6 text-left"
                        style={{ color: '#116565' }}
                      >
                        {item?.name}
                      </Typography.Text>
                    }
                    key={index.toString()}
                  >
                    <ManifestTenents
                      tabKey={tabKey}
                      productOf={productOf}
                      carrier={item}
                      active={tabKey === index.toString()}
                    />
                  </TabPane>
                );
              })}
            </Tabs>
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default Manifest;
