import { Button, Card, Col, Input, InputNumber, Row, Tag, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Table, { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import TenantInfo from '../../components/TenantIdInfo';
import { getAccess, readableString } from '../../constants/common-constants';
import { fileHelpers } from '../../helpers';
import { formatDate } from '../../helpers/date.helpers';
import { fetchReqData } from '../../helpers/file.helpers';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService, productService } from '../../modules/nebula/services';
import { useLoader } from '../../stores/use-loader';

interface IPackStation {
  productOf: string;
}

const PackStation: React.FunctionComponent<IPackStation> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { roNumber } = useParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const [orderListing, setOrderListing] = React.useState({} as any);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);
  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, any>);

  const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);

  const columns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      render(value, record, index) {
        let data = record?.fo_details?.length > 0 ? record.fo_details[0].order_number : '-';
        return <Typography.Text>{data}</Typography.Text>;
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text className="text-[#5e5f66]">{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      render(value, record, index) {
        let date =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('DD/MM/YYYY')
              : '-'
            : '-';
        let time =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('HH:mm:ss')
              : ''
            : '';
        return (
          <Typography.Text>
            {' '}
            <>
              {date} {time}
            </>
          </Typography.Text>
        );
      }
    },
    {
      title: t('releaseDateM'),
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'release-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Requested Ship Date',
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Requested Delivery Date',
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'AWB Number',
      align: 'center',
      render(value, record, index) {
        // let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{record.ro_number.replace(/[a-zA-Z]/g, '2')}</Typography.Text>;
      }
    },
    {
      title: 'Ship Node',
      align: 'center',
      render: (_, record) => {
        return <>{record?.node_name || '-'}</>;
      }
    },
    {
      title: t('shippingMethod'),
      align: 'center',
      render(value, record, index) {
        let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{deliveryMethod}</Typography.Text>;
      }
    },
    {
      title: t('orderedQtyM'),
      align: 'center',
      render(value, record, index) {
        let quantity = 0;
        record?.fo_details.forEach((fo: any) => {
          const qty = fo.item_list?.reduce((acc: any, curr: any) => {
            return acc + curr.original_quantity?.number_of_units;
          }, 0);
          quantity += qty;
        });
        return <Typography.Text>{quantity}</Typography.Text>;
      }
    }
  ];
  const expandedRowRender = () => {
    if (_.isEmpty(expandedRowData[expandedRowKeys[0]])) return <>{t('noItemsInShipment')}</>;
    const columns: ColumnsType<any> = [
      {
        title: t('itemID'),
        align: 'center',
        render(value, record, index) {
          return <Typography.Text>{record?.sku_id}</Typography.Text>;
        }
      },
      {
        title: t('itemImage'),
        align: 'center',
        render(value, record, index) {
          let url = record?.skuImageURL || '';
          return (
            <>
              <img
                src={url}
                alt="NOIMAGE"
                style={{
                  height: '200px',
                  width: '200px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }}
              />
            </>
          );
        }
      },
      {
        title: t('itemDescription'),
        align: 'center',
        render(value, record, index) {
          return <Typography.Text className="text-[#5e5f66]">{record?.skuDescription}</Typography.Text>;
        }
      },
      {
        title: t('orderedQtyM'),
        align: 'center',
        render(value, record, index) {
          return <Typography.Text className="text-[#5e5f66]">{record?.quantity}</Typography.Text>;
        }
      },
      {
        title: `Pack Quantity`,
        align: 'center',
        render(value, record, index) {
          //   return <Typography.Text className="text-[#5e5f66]">{'-'}</Typography.Text>;
          return record.status_description === 'PICKED' ? (
            <InputNumber
              min={0}
              max={record.quantity}
              value={record.picked_qty}
              onChange={event =>
                handleItemLevelFieldChange(record.ro_number, record.fo_number, record.sku_id, 'picked_qty', event)
              }
            />
          ) : (
            <Typography.Text className="text-[#5e5f66]">
              {
                record.tags[0].statuses // .filter((tag: any) => tag.tag_id === 'DEFAULT')[0]
                  .filter((status: any) => status.status_description === 'PACKED')[0].quantity.number_of_units
              }
            </Typography.Text>
          );
        }
      },
      {
        title: 'Scan  Barcode',
        align: 'center',
        render(value, record, index) {
          return <Input />;
        }
      },
      {
        title: 'Cancelled Quantity',
        align: 'center',
        render(value, record, index) {
          return (
            // <Button
            //   type="link"
            //   onClick={() => {
            //     setCancelQtyModal(true);
            //     setCancelQtydata(record);
            //   }}
            // >
            //   {record.cancelled_qty || 0} / {record?.quantity} Cancel
            // </Button>
            <InputNumber
              min={0}
              max={record.quantity}
              value={record.cancelled_qty}
              onChange={event =>
                handleItemLevelFieldChange(record.ro_number, record.fo_number, record.sku_id, 'cancelled_qty', event)
              }
            />
          );
        }
      },
      {
        title: 'Reason',
        align: 'center',
        render(value, record, index) {
          return (
            <TextArea
              rows={4}
              placeholder="Enter reason"
              //   maxLength={6}
              value={record.cancel_reason}
              onChange={event =>
                handleItemLevelFieldChange(
                  record.ro_number,
                  record.fo_number,
                  record.sku_id,
                  'cancel_reason',
                  event.target.value
                )
              }
            />
          );
        }
      }
    ];

    const fos = expandedRowData[expandedRowKeys[0]]?.fo_details || [];
    const fulfilInfo = expandedRowData[expandedRowKeys[0]]?.fulfilment_info || {};
    const roDetails = expandedRowData[expandedRowKeys[0]];
    const tableData: any = [];

    fos.forEach((fo: any) => {
      const { channel_id } = fo;
      fo.item_list.forEach((item: any) => {
        const { sku_id, product_desc, status_description, total_amount } = item;
        tableData.push({
          ...item,
          channel_id: channel_id,
          fo_number: fo.fo_number,
          order_number: fo.order_number,
          sku_id: sku_id,
          key: sku_id,
          description: product_desc,
          created_date: roDetails?.created_datetime,
          status: status_description,
          quantity: item.quantity?.number_of_units,
          fulfillment_type: fulfilInfo?.delivery_method,
          status_description: roDetails.status_description,
          amount: total_amount,
          order_start_trans_datetime: fo.order_start_trans_datetime,
          //   cancelled_qty: 0,
          //   cancel_reason: '',
          ro_number: expandedRowKeys[0]
        });
      });
    });

    const shipmentInfo = roDetails?.shipment_details;

    const roId = orderListing.releaseorder_list.find((ro: any) => ro.ro_number === expandedRowKeys[0])?.id || '';

    return (
      <section className="pl-8">
        <Card>
          <section className="flex items-center gap-4 my-2 justify-between">
            <div className="my-auto font-bold text-base">{t('items')}</div>
          </section>
          <Table className="mt-2" bordered columns={columns} dataSource={tableData} pagination={false} />
        </Card>
      </section>
    );
  };

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  React.useEffect(() => {
    if (orderListing?.releaseorder_list?.length > 0) {
      const firstRow = orderListing.releaseorder_list[0];
      handleFetchShipments(firstRow.ro_number, firstRow);
      setExpandedRowKeys([firstRow.ro_number]);
    }
  }, [orderListing]);

  const handleSearch = async (offset = 0) => {
    setLoading(true);

    // const ro_number = 'NRO000000364';
    const params = {
      ro_number: roNumber,
      offset,
      limit: pageControl?.pageSize
    };

    const { data, errors } = await orderService.fetchRoList(params);
    if (_.isEmpty(errors)) {
      const roResponse: any = data;

      const temp = roResponse?.releaseorder_list?.map((order: any) => ({
        ...order,
        fo_details: order.fo_details.map((fo: any) => ({
          ...fo,
          item_list: fo.item_list.map((item: any) => ({
            ...item,
            picked_qty: 0, // Initialize picked_qty to 0
            cancelled_qty: 0,
            cancel_reason: ''
          }))
        }))
      }));

      const allSkuIds = temp?.releaseorder_list?.flatMap((obj: any) =>
        obj.fo_details.flatMap((detail: any) => detail.item_list.map((item: any) => item.sku_id))
      );
      let withImageData = await getSkuImages(allSkuIds, roResponse);

      // let newRespImgURL = await getImageUrl(temp[0]);

      let combineData: any = {
        page_info: temp.page_info,
        releaseorder_list: withImageData
      };

      const updatedRoList = combineData.releaseorder_list.map((ro: any) => ({ ...ro, key: ro?.ro_number }));
      setOrderListing({ ...combineData, releaseorder_list: updatedRoList });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const getSkuImages = async (skuArray: any, listingData: any) => {
    const payload = {
      sku_ids: skuArray
    };
    const { data, errors } = await orderService.getBulkImages(payload);
    if (_.isEmpty(errors)) {
      const skuImageMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.image_urls?.[0] || ''];
        })
      );
      const skuDescriptionMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.description];
        })
      );
      const updatedData1 = listingData?.releaseorder_list?.map((obj: any) => ({
        ...obj,
        fo_details: obj?.fo_details?.map((detail: any) => ({
          ...detail,
          item_list: detail?.item_list?.map((item: any) => {
            let obj = {
              ...item,
              skuImageURL: skuImageMap[item.sku_id] || '',
              skuDescription: skuDescriptionMap[item.sku_id]
            };
            return obj;
          })
        }))
      }));
      return updatedData1;
    } else {
      displayErrorNotifications(errors);
    }
  };

  const loadInitialData = async () => {
    await handleSearch(pageControl?.currentPage - 1);
  };

  const handleFetchShipments = async (roNumber: string, record: any) => {
    const updatedFoDetails = await Promise.all(
      record.fo_details.map(async (fo: any) => ({
        ...fo,
        item_list: await Promise.all(
          fo.item_list.map(async (item: any) => {
            try {
              const { data, errors } = await productService.getAllProductsFromCategoriesPromise([item.sku_id], 'BASIC');

              const imageUrl = data?.image_url || '';

              return { ...item, image_url: imageUrl };
            } catch (error) {
              return { ...item, image_url: '' };
            }
          })
        )
      }))
    );

    setExpandedRowData(expandedRowData => ({ ...expandedRowData, [roNumber]: record }));
  };

  const handleItemLevelFieldChange = (roNumber: string, foNumber: string, skuId: string, field: string, value: any) => {
    setOrderListing((prevOrderListing: any) => {
      const updatedReleaseOrders = prevOrderListing.releaseorder_list.map((order: any) => {
        if (order.ro_number !== roNumber) return order;

        return {
          ...order,
          fo_details: order.fo_details.map((fo: any) => {
            if (fo.fo_number !== foNumber) return fo;

            return {
              ...fo,
              item_list: fo.item_list.map((item: any) => (item.sku_id === skuId ? { ...item, [field]: value } : item))
            };
          })
        };
      });

      return { ...prevOrderListing, releaseorder_list: updatedReleaseOrders };
    });

    setExpandedRowData(prevData => {
      if (!prevData[roNumber]) return prevData; // Ensure row exists

      return {
        ...prevData,
        [roNumber]: {
          ...prevData[roNumber],
          fo_details: prevData[roNumber].fo_details.map((fo: any) =>
            fo.fo_number !== foNumber
              ? fo
              : {
                  ...fo,
                  item_list: fo.item_list.map((item: any) =>
                    item.sku_id === skuId ? { ...item, [field]: value } : item
                  )
                }
          )
        }
      };
    });
  };

  const handlePack = async (ro: any) => {
    let isValid = true;
    let errorMessages: { message: string }[] = [];
    ro.fo_details.forEach((fo: any) => {
      fo.item_list.forEach((item: any) => {
        const totalQuantity = item.quantity?.number_of_units;
        const pickedQty = item?.picked_qty || 0;
        const cancelledQty = item?.cancelled_qty || 0;

        if (pickedQty + cancelledQty !== totalQuantity) {
          errorMessages.push({
            message: `${item.sku_id}: Pack quantity and cancelled quantity must equal the total available quantity.`
          });
          isValid = false;
        }

        if (cancelledQty > 0 && !item.cancel_reason) {
          errorMessages.push({
            message: `${item.sku_id}: A cancellation reason is required for the entered cancelled quantity.`
          });
          isValid = false;
        }
      });
    });
    if (errorMessages.length > 0) {
      displayErrorNotifications(errorMessages);
      return;
    }
    setLoading(true);

    if (isValid) {
      const invoice_data = await generateInvoice(ro);

      if (invoice_data?.invoice_number) {
        const bodyData = {
          ro_number: ro.ro_number,
          invoice_number: invoice_data?.invoice_number, //
          // invoice_url: 'string', //
          status: 'PACKED', //
          status_datetime: new Date().toISOString(),
          mark_remaining_as_backorder: true,
          parcel: [
            //considering 1 packasge only
            {
              awb_no: ro.ro_number.replace(/[a-zA-Z]/g, '2'),
              package_details: {
                package_height_in_cms: 5,
                package_length_in_cms: 10,
                package_weight_in_kgs: 1,
                package_width_in_cms: 10
              },
              lines: ro.fo_details.map((fo: any) => {
                return fo.item_list.map((item: any) => {
                  return {
                    order_number: fo.order_number,
                    fo_number: fo.fo_number,
                    co_line_id: item.co_line_id,
                    sku_id: item.sku_id,
                    fulfilled_quantity: [
                      {
                        tag_id: item.tags[0].tag_id,
                        serial_id: item?.serial_id ? item?.serial_id : null,
                        mark_remaining_as_backorder: true,
                        quantity: {
                          unit_size: 1,
                          unit_of_measure: 'EA',
                          number_of_units: item?.picked_qty || 0,
                          unit_fraction: 1
                        },
                        back_order_quantity: {
                          unit_size: 1,
                          unit_of_measure: 'EA',
                          number_of_units: item?.cancelled_qty || 0,
                          unit_fraction: 1
                        },
                        reason: item.cancel_reason
                      }
                    ]
                  };
                })[0];
              })
            }
          ]
        };

        const { data, errors } = await orderService.releaseOrderPackUpdate(bodyData);
        if (_.isEmpty(errors)) {
          loadInitialData();
        } else {
          displayErrorNotifications(errors);
        }
      } else {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const generateInvoice = async (ro: any) => {
    const billToDetails = ro.address.filter((add: any) => add.address_tag === 'bill-to-details')[0];
    const shipToDetails = ro.address.filter((add: any) => add.address_tag === 'ship-to-details')[0];
    const dispatchFromDetails = ro.address.filter((add: any) => add.address_tag === 'dispatch-from-details')[0];

    const payload = {
      tenant_id: ro.tenant_id,
      supply_type_code: ro.supply_type_code,
      reverse_tax_applicable: true,
      order_number: ro.fo_details[0].order_number,
      order_start_trans_datetime: ro.fo_details[0].order_start_trans_datetime,
      ro_number: ro.ro_number,
      business_group_id: ro.business_group_id,
      fo_number: ro.fo_details[0].fo_number,
      item_list: ro.fo_details[0].item_list.map((item: any) => {
        return {
          description: item.skuDescription,
          gross_amount: item?.gross_amount ? item.gross_amount : 0,
          total_discount: item.total_discount,
          total_item_value: item.total_item_value,
          list_price: item.list_price,
          total_tax: item.total_tax,
          unit_price: item.unit_price,
          retail_price: item?.retail_price ? item.retail_price : null, //?mrp should in in ro(list price)
          charges: item?.charges
            ? [
                {
                  charge_name: item?.charges[0]?.charge_name ? item?.charges[0]?.charge_name : null,
                  amount: item?.charges[0]?.amount ? item?.charges[0]?.amount : null,
                  charge_category: item?.charges[0]?.charge_category ? item?.charges[0]?.charge_category : null,
                  charge_key_ref: item?.charges[0]?.charge_key_ref ? item?.charges[0]?.charge_key_ref : null,
                  product_id: item.sku_id,
                  is_billable: item?.charges[0]?.is_billable ? item?.charges[0]?.is_billable : null,
                  round_off_amount: item?.charges[0]?.round_off_amount ? item?.charges[0]?.round_off_amount : null,
                  charge_per_unit: item?.charges[0]?.charge_per_unit ? item?.charges[0]?.charge_per_unit : null,
                  hsn_code: item?.charges[0]?.hsn_code ? item?.charges[0]?.hsn_code : '123',
                  is_taxable: item.is_taxable,
                  is_discount: item?.charges[0]?.is_discount ? item?.charges[0]?.is_discount : null
                }
              ]
            : null,
          taxes: item?.taxes
            ? [
                {
                  tax_code: item.taxes[0].tax_code ? item.taxes[0].tax_code : null,
                  charge_key_ref: item.taxes[0].charge_key_ref ? item.taxes[0].charge_key_ref : null,
                  charge_name: 'tax',
                  price: item.list_price,
                  product_id: item.sku_id,
                  hsn_code: item?.taxes[0]?.hsn_code ? item?.taxes[0]?.hsn_code : '123',
                  tax_rate: item.taxes[0].tax_rate ? item.taxes[0].tax_rate : 0
                }
              ]
            : [
                {
                  tax_code: 'tax code',
                  // charge_key_ref: item.taxes[0].charge_key_ref ? item.taxes[0].charge_key_ref : null,
                  charge_name: 'tax',
                  price: item.list_price,
                  product_id: item.sku_id,
                  hsn_code: '123',
                  tax_rate: 0
                }
              ],
          quantity: {
            unit_size: 1,
            unit_of_measure: 'EA',
            number_of_units: item?.picked_qty || 0,
            unit_fraction: 1
          }
        };
      }),
      node_id: ro.node_id,
      dispatch_from_details: {
        address_tag: dispatchFromDetails.address_tag,
        address_info: {
          country: dispatchFromDetails.address_info.country,
          country_code: dispatchFromDetails.address_info.country_code,
          address_line1: dispatchFromDetails.address_info.address_line1,
          address_id: dispatchFromDetails.address_info.address_id ? dispatchFromDetails.address_info.address_id : null,
          coordinates: dispatchFromDetails.address_info.coordinates
            ? dispatchFromDetails.address_info.coordinates
            : null,
          location: dispatchFromDetails.address_info.location,
          state: dispatchFromDetails.address_info.state,
          postal_code: dispatchFromDetails.address_info.postal_code,
          state_code: dispatchFromDetails.address_info.state_code
        },
        last_name: dispatchFromDetails.last_name,
        phone_number: [
          {
            country_code: dispatchFromDetails.phone_number[0].country_code,
            number: dispatchFromDetails.phone_number[0].number,
            type: dispatchFromDetails.phone_number[0].type
          }
        ],
        first_name: dispatchFromDetails.first_name
      },
      bill_to_details: {
        address_tag: billToDetails.address_tag,

        address_info: {
          country: billToDetails.address_info.country,
          country_code: billToDetails.address_info.country_code,
          address_line2: billToDetails.address_info?.address_line2 ? billToDetails.address_info?.address_line2 : '',
          address_line1: billToDetails.address_info.address_line1,
          address_id: billToDetails.address_info.address_id ? billToDetails.address_info.address_id : null,
          coordinates: billToDetails.address_info.coordinates ? billToDetails.address_info.coordinates : null,
          location: billToDetails.address_info.location,
          state: billToDetails.address_info.state,
          postal_code: billToDetails.address_info.postal_code,
          state_code: billToDetails.address_info.state_code
        },
        last_name: billToDetails.last_name,
        is_addr_same_as_ship_to: billToDetails.is_addr_same_as_ship_to,
        phone_number: [
          {
            country_code: billToDetails.phone_number[0].country_code,
            number: billToDetails.phone_number[0].number,
            type: billToDetails.phone_number[0].type
          }
        ],
        first_name: billToDetails.first_name,
        is_cml_address: billToDetails.is_cml_address
      },
      ship_to_details: {
        address_tag: shipToDetails.address_tag,
        address_info: {
          country: shipToDetails.address_info.country,
          country_code: shipToDetails.address_info.country_code,
          address_line2: shipToDetails.address_info?.address_line2 ? billToDetails.address_info?.address_line2 : '',
          address_line1: shipToDetails.address_info.address_line1,
          address_id: shipToDetails.address_info.address_id ? shipToDetails.address_info.address_id : null,
          coordinates: shipToDetails.address_info.coordinates ? shipToDetails.address_info.coordinates : null,
          location: shipToDetails.address_info.location,
          state: shipToDetails.address_info.state,
          postal_code: shipToDetails.address_info.postal_code,
          state_code: shipToDetails.address_info.state_code
        },
        last_name: shipToDetails.last_name,
        is_addr_same_as_ship_to: shipToDetails.is_addr_same_as_ship_to,
        phone_number: [
          {
            country_code: shipToDetails.phone_number[0].country_code,
            number: shipToDetails.phone_number[0].number,
            type: shipToDetails.phone_number[0].type
          }
        ],
        first_name: shipToDetails.first_name,
        is_cml_address: shipToDetails.is_cml_address
      },
      value_details: {
        taxable_value: ro.value_details.taxable_value,
        other_charges: ro.value_details.other_charges,
        discount_value: ro.value_details.discount_value,
        taxes:
          ro.value_details.taxes.length > 0
            ? [
                {
                  tax_code: ro.value_details.taxes[0].tax_code ? ro.value_details.taxes[0].tax_code : null,
                  price: ro.value_details.taxes[0].price ? ro.value_details.taxes[0].price : 0,
                  tax_rate: ro.value_details.taxes[0].tax_rate ? ro.value_details.taxes[0].tax_rate : 0,
                  type: ro.value_details.taxes[0].type ? ro.value_details.taxes[0].type : null
                }
              ]
            : [
                {
                  tax_code: 'tax code', //?
                  price: 0,
                  tax_rate: 0,
                  type: 'tax' //?
                }
              ],
        // round_off_amount: 0,
        gross_amount: ro.value_details.gross_amount,
        total_order_value: ro.value_details.total_order_value
      }
    };

    const { data, errors } = await orderService.generateInvoice(payload);
    if (_.isEmpty(errors)) {
      return data;
    } else {
      displayErrorNotifications(errors);
      return null;
    }
  };

  const handlePackSlip = async (ro: any) => {
    setLoading(true);
    const billToDetails = ro.address.filter((add: any) => add.address_tag === 'bill-to-details')[0];
    const shipToDetails = ro.address.filter((add: any) => add.address_tag === 'ship-to-details')[0];
    const payload = {
      doc_type: 'PACK_SLIP',
      document_format: 'PDF',
      data: {
        tenant_id: ro.tenant_id,
        supply_type_code: ro.supply_type_code,
        node_id: ro.node_id,
        node_name: ro.node_name,
        pack_slip: {
          ro_number: ro.ro_number,
          created_datetime: new Date().toISOString(),
          ship_to_details: {
            address_tag: 'SHIP_TO_DETAILS',
            address_info: {
              country: shipToDetails.address_info.country,
              country_code: shipToDetails.address_info.country_code,
              address_line1: shipToDetails.address_info.address_line1,
              address_id: shipToDetails.address_info.address_id ? shipToDetails.address_info.address_id : null,
              coordinates: shipToDetails.address_info.coordinates ? shipToDetails.address_info.coordinates : null,
              location: shipToDetails.address_info.location,
              state: shipToDetails.address_info.state,
              postal_code: shipToDetails.address_info.postal_code,
              state_code: shipToDetails.address_info.state_code
            },
            last_name: shipToDetails.last_name,
            phone_number: [
              {
                country_code: shipToDetails.phone_number[0].country_code,
                number: shipToDetails.phone_number[0].number,
                type: shipToDetails.phone_number[0].type
              }
            ],
            first_name: shipToDetails.first_name
          },
          bill_to_details: {
            address_tag: 'BILL_TO_DETAILS',
            address_info: {
              country: billToDetails.address_info.country,
              country_code: billToDetails.address_info.country_code,
              address_line1: billToDetails.address_info.address_line1,
              address_id: billToDetails.address_info.address_id ? billToDetails.address_info.address_id : null,
              coordinates: billToDetails.address_info.coordinates ? billToDetails.address_info.coordinates : null,
              location: billToDetails.address_info.location,
              state: billToDetails.address_info.state,
              postal_code: billToDetails.address_info.postal_code,
              state_code: billToDetails.address_info.state_code
            },
            last_name: billToDetails.last_name,
            phone_number: [
              {
                country_code: billToDetails.phone_number[0].country_code,
                number: billToDetails.phone_number[0].number,
                type: billToDetails.phone_number[0].type
              }
            ],
            first_name: billToDetails.first_name
          },
          item_list: ro.fo_details[0].item_list.map((item: any) => {
            return {
              item_id: item.sku_id,
              item_description: item.skuDescription,
              quantity: item.tags[0].statuses // .filter((tag: any) => tag.tag_id === 'DEFAULT')[0]
                .filter((status: any) => status.status_description === 'PACKED')[0].quantity
            };
          })
        }
      }
    };
    const { data, errors } = await orderService.generatePackSlip(payload);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'PackSlip', extension: '.pdf' });
      // openFileInNewTab({ data });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleShippingLabel = async (ro: any) => {
    setLoading(true);

    const dispatchFromDetails = ro.address.filter((add: any) => add.address_tag === 'dispatch-from-details')[0];
    const shipToDetails = ro.address.filter((add: any) => add.address_tag === 'ship-to-details')[0];
    // let quantity = 0;
    // ro?.fo_details.forEach((fo: any) => {
    //   const qty = fo.item_list?.reduce((acc: any, curr: any) => {
    //     return acc + curr.original_quantity?.number_of_units;
    //   }, 0);
    //   quantity += qty;
    // });
    let totalPackedQuantity = 0;

    ro?.fo_details.forEach((fo: any) => {
      fo.item_list?.forEach((item: any) => {
        const firstTag = item.tags[0]; // Get the first tag from the array
    
        if (firstTag) {
          const packedQuantity = firstTag.statuses
            .filter((status: any) => status.status_description === 'PACKED') // Get only "PACKED" statuses
            .reduce((acc: number, status: any) => acc + status.quantity.number_of_units, 0); // Sum up quantities
    
          totalPackedQuantity += packedQuantity;
        }
      });
    });


    const payload = {
      doc_type: 'SHIPPING_LABEL',
      document_format: 'PDF',
      data: {
        tenant_id: ro.tenant_id,
        supply_type_code: ro.supply_type_code,
        node_id: ro.node_id,
        node_name: ro.node_name,
        shipping_label: {
          order_number: ro.fo_details[0].order_number, //considering we will have only 1 fo as its not an array
          ro_number: ro.ro_number,
          created_datetime: new Date().toISOString(),
          awb_number: ro.ro_number.replace(/[a-zA-Z]/g, '2'),
          quantity: {
            unit_size: 1,
            unit_of_measure: 'EA',
            number_of_units: totalPackedQuantity,
            unit_fraction: 1
          },
          package_details: {
            // hardcoded
            package_height_in_cms: 5,
            package_length_in_cms: 5,
            package_width_in_cms: 5,
            package_weight_in_kgs: 1
          },
          dispatch_from_details: {
            address_info: {
              country: dispatchFromDetails.address_info.country,
              country_code: dispatchFromDetails.address_info.country_code,
              address_line1: dispatchFromDetails.address_info.address_line1,
              address_id: dispatchFromDetails.address_info.address_id
                ? dispatchFromDetails.address_info.address_id
                : null,
              coordinates: dispatchFromDetails.address_info.coordinates
                ? dispatchFromDetails.address_info.coordinates
                : null,
              location: dispatchFromDetails.address_info.location,
              state: dispatchFromDetails.address_info.state,
              postal_code: dispatchFromDetails.address_info.postal_code,
              state_code: dispatchFromDetails.address_info.state_code
            },
            last_name: dispatchFromDetails.last_name,
            phone_number: [
              {
                country_code: dispatchFromDetails.phone_number[0].country_code,
                number: dispatchFromDetails.phone_number[0].number,
                type: dispatchFromDetails.phone_number[0].type
              }
            ],
            first_name: dispatchFromDetails.first_name
          },
          ship_to_details: {
            address_info: {
              country: shipToDetails.address_info.country,
              country_code: shipToDetails.address_info.country_code,
              address_line1: shipToDetails.address_info.address_line1,
              address_id: shipToDetails.address_info.address_id ? shipToDetails.address_info.address_id : null,
              coordinates: shipToDetails.address_info.coordinates ? shipToDetails.address_info.coordinates : null,
              location: shipToDetails.address_info.location,
              state: shipToDetails.address_info.state,
              postal_code: shipToDetails.address_info.postal_code,
              state_code: shipToDetails.address_info.state_code
            },
            last_name: shipToDetails.last_name,
            phone_number: [
              {
                country_code: shipToDetails.phone_number[0].country_code,
                number: shipToDetails.phone_number[0].number,
                type: shipToDetails.phone_number[0].type
              }
            ],
            first_name: shipToDetails.first_name
          }
        }
      }
    };
    const { data, errors } = await orderService.generateShippingLabel(payload);

    if (_.isEmpty(errors)) {
      // const blob = await data.blob(); // Convert to blob
      // const url = URL.createObjectURL(blob);
      // setPreviewUrl(url);
      // // setVisible(true);
      // setIsModalOpen(true);

      fileHelpers.triggerFileDownload({ data, fileName: `${ro.ro_number}-ShippingLabel`, extension: '.pdf' });
      // openFileInNewTab({ data });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const handlePrintInvoice = async (ro: any) => {
    const bodyData = {
      doc_type: 'INVOICE',
      document_format: 'PDF',
      data: {
        invoice_number: ro.invoice.invoice_number
      }
    };
    const { data, errors } = await orderService.generateInvoice1(bodyData);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: `${ro.ro_number}-invoice`, extension: '.pdf' });
      // openFileInNewTab({ data });
    } else {
      displayErrorNotifications(errors);
    }
  };

  // const handlePrint = () => {
  //   if (pdfUrl) {
  //     const newWindow = window.open(pdfUrl);
  //     if (newWindow) {
  //       newWindow.onload = () => newWindow.print();
  //     }
  //   }
  // };
  const openFileInNewTab = ({ data }: { data: Blob }) => {
    const blob = new Blob([data], { type: 'application/pdf' }); // Ensure correct MIME type
    const url = URL.createObjectURL(blob);

    // Open in new tab
    window.open(url, '_blank');

    // Cleanup: Revoke URL after some time
    setTimeout(() => URL.revokeObjectURL(url), 5000);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        {orderListing?.releaseorder_list && (
          <Card>
            <Row className="mb-4" gutter={12}>
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Pack Station
                  {/* {t('acknowledgeOrders')} */}
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <Row justify={'space-between'} className="mb-4 mt-4" gutter={12}>
              <Col md={6} xs={12}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => handlePack(orderListing?.releaseorder_list[0])}
                  block
                  disabled={!(orderListing?.releaseorder_list[0]?.status_description === 'PICKED')}
                >
                  <BoldButtonLabel labelText={'Pack'} />
                </Button>
              </Col>
              <Col md={6} xs={12}>
                <Button
                  type="primary"
                  size="large"
                  block
                  disabled={
                    !(
                      orderListing?.releaseorder_list[0]?.status_description === 'PACKED' ||
                      orderListing?.releaseorder_list[0]?.status_description === 'READY_TO_SHIP'
                    )
                  }
                  onClick={() => handlePrintInvoice(orderListing?.releaseorder_list[0])}
                >
                  <BoldButtonLabel labelText={'Generate Invoice'} />
                </Button>
              </Col>
              <Col md={6} xs={12}>
                <Button
                  type="primary"
                  size="large"
                  block
                  disabled={
                    !(
                      orderListing?.releaseorder_list[0]?.status_description === 'PACKED' ||
                      orderListing?.releaseorder_list[0]?.status_description === 'READY_TO_SHIP'
                    )
                  }
                  onClick={() => handleShippingLabel(orderListing?.releaseorder_list[0])}
                >
                  <BoldButtonLabel labelText={'Generate Shipping Label'} />
                </Button>
              </Col>
              <Col md={6} xs={12}>
                {/* <Button
                  type="primary"
                  size="large"
                  block
                  disabled={
                    !(
                      orderListing?.releaseorder_list[0]?.status_description === 'PACKED' ||
                      orderListing?.releaseorder_list[0]?.status_description === 'READY_TO_SHIP'
                    )
                  }
                  onClick={() => handlePackSlip(orderListing?.releaseorder_list[0])}
                >
                  <BoldButtonLabel labelText={'Generate Pack Slip'} />
                </Button> */}
              </Col>
            </Row>
            <Table
              loading={false}
              className="mt-4"
              expandable={{
                expandedRowRender,
                expandedRowKeys,
                async onExpand(expanded, record) {
                  if (expandedRowKeys.includes(record.ro_number)) {
                    return setExpandedRowKeys([]);
                  }
                  if (expanded) {
                    if (_.isEmpty(expandedRowData[record.ro_number])) {
                      await handleFetchShipments(record.ro_number, record);
                    }
                    setExpandedRowKeys([record.ro_number]);
                  }
                },
                rowExpandable: record => record?.ro_number
              }}
              pagination={{
                current: pageControl?.currentPage,
                total: orderListing?.page_info?.total_pages * pageControl?.pageSize || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onChange: (currentPage, pageSize) =>
                  setPageControl({
                    currentPage,
                    pageSize
                  })
              }}
              locale={{
                emptyText: t('noData')
              }}
              bordered
              dataSource={orderListing?.releaseorder_list || []}
              columns={columns}
              scroll={{ x: 1000 }}
            />
          </Card>
        )}
      </div>
      {/* <Modal
        title="Bit Stream Preview"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="print" type="primary" onClick={handlePrint}>
            Print
          </Button>,
          <Button key="close" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>,
        ]}
      >
        {previewUrl ? (
         <PDFViewer url="http://www.africau.edu/images/default/sample.pdf" />

        ) : (
          <p>Loading preview...</p>
        )}
      </Modal> */}
    </PrimaryLayout>
  );
};
export default PackStation;
