import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import TenantInfo from '../../components/TenantIdInfo';
import { getAccess, readableString } from '../../constants/common-constants';
import { objectHelpers } from '../../helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService } from '../../modules/nebula/services';
import { useLoader } from '../../stores/use-loader';
import { generateUUIDV4 } from '../../helpers/uuid.helpers';
import { fetchReqData } from '../../helpers/file.helpers';
import { formatDate, resetISOTimeStampTimeToZeroIST } from '../../helpers/date.helpers';
import OrderListingPage from '../orders';

interface IManifest {
  productOf: string;
}

const OpenOrderListing: React.FunctionComponent<IManifest> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [manifestForm] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
  const [selectedOrderList, setSelectedOrderList] = React.useState<React.Key[]>([]);
  const [reasonModal, setReasonModal] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openOrderListing, setOpenOrderListing] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      render(value, record, index) {
        let data = record?.fo_details?.length > 0 ? record.fo_details[0].order_number : '-';
        return <Typography.Text>{data}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      render(value, record, index) {
        let date =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('DD/MM/YYYY')
              : '-'
            : '-';
        // let time =
        //   record.fo_details.length > 0
        //     ? record?.fo_details[0]?.order_start_trans_datetime
        //       ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('HH:mm:ss')
        //       : ''
        //     : '';
        return (
          <Typography.Text>
            <>{date}</>
          </Typography.Text>
        );
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('releaseDateM'),
      align: 'center',
      render(value, record, index) {
        let date = record?.created_datetime ? dayjs(record?.created_datetime).format('DD/MM/YYYY') : '-';
        // let time = record?.created_datetime ? dayjs(record?.created_datetime).format('HH:mm:ss') : '-';
        return (
          <Typography.Text>
            <>{date}</>
          </Typography.Text>
        );
      }
    },
    {
      title: 'Requested Ship Date',
      align: 'center',
      render: (_, record) => {
        let dateData = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date')
          : '';
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: t('Ship Node'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.node_name}</Typography.Text>;
      }
    },
    {
      title: t('shippingMethod'),
      align: 'center',
      render(value, record, index) {
        let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{deliveryMethod}</Typography.Text>;
      }
    },
    {
      title: 'Requested Delivery Date',
      align: 'center',
      render: (_, record) => {
        const promiseDeliveryDate = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date')
          : '';
        return <>{promiseDeliveryDate ? `${formatDate(promiseDeliveryDate?.date_time_stamp)}` : ''}</>;
      }
    },
    {
      title: t('orderedQtyM'),
      align: 'center',
      render(value, record, index) {
        let quantity = 0;
        record?.fo_details.forEach((fo: any) => {
          const qty = fo.item_list?.reduce((acc: any, curr: any) => {
            return acc + curr.original_quantity?.number_of_units;
          }, 0);
          quantity += qty;
        });
        return <Typography.Text>{quantity}</Typography.Text>;
      }
    }
  ];

  const childRendererColumns: ColumnsType<any> = [
    {
      title: t('itemID'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.sku_id}</Typography.Text>;
      }
    },
    {
      title: t('itemDescription'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.skuDescription}</Typography.Text>;
      }
    },
    {
      title: t('itemImage'),
      align: 'center',
      render(value, record, index) {
        let url = record?.skuImageURL || '';
        return (
          <>
            <img
              src={url}
              alt="NOIMAGE"
              style={{
                height: '65px',
                width: '60px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            />
          </>
        );
      }
    },
    {
      title: t('quantity'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.original_quantity?.number_of_units}</Typography.Text>;
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    getOpenOrderList();
  };

  const getSkuImages = async (skuArray: any, listingData: any) => {
    const payload = {
      sku_ids: skuArray
    };
    const { data, errors } = await orderService.getBulkImages(payload);
    if (_.isEmpty(errors)) {
      const skuImageMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.image_urls?.[0] || ''];
        })
      );
      const skuDescriptionMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.description];
        })
      );
      const updatedData1 = listingData?.releaseorder_list?.map((obj: any) => ({
        ...obj,
        fo_details: obj?.fo_details?.map((detail: any) => ({
          ...detail,
          item_list: detail?.item_list?.map((item: any) => {
            let obj = {
              ...item,
              skuImageURL: skuImageMap[item.sku_id] || '',
              skuDescription: skuDescriptionMap[item.sku_id]
            };
            return obj;
          })
        }))
      }));
      return updatedData1;
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getOpenOrderList = async () => {
    setLoading(true);
    const page_size = parseInt(searchParams.get('page_size') || '') || 10;
    const page_number = parseInt(searchParams.get('page_number') || '') || 1;
    const { order_date_range, release_date_range } = manifestForm.getFieldsValue();
    const params = {
      offset: page_number - 1,
      limit: page_size,
      status: 'RELEASED',
      channel_id: 'shopify',
      ...manifestForm.getFieldsValue(),
      order_created_after: order_date_range ? resetISOTimeStampTimeToZeroIST(order_date_range[0]) : undefined,
      order_created_before: order_date_range ? resetISOTimeStampTimeToZeroIST(order_date_range[1]) : undefined,
      created_after: release_date_range ? resetISOTimeStampTimeToZeroIST(release_date_range[0]) : undefined,
      created_before: release_date_range ? resetISOTimeStampTimeToZeroIST(release_date_range[1]) : undefined,
      order_date_range: undefined,
      release_date_range: undefined
    };
    const queryParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(queryParams);
    const { data, errors } = await orderService.getOrderFulfillment(queryParams);
    if (_.isEmpty(errors)) {
      const roResponse: any = data;
      const allSkuIds = roResponse?.releaseorder_list?.flatMap((obj: any) =>
        obj.fo_details.flatMap((detail: any) => detail.item_list.map((item: any) => item.sku_id))
      );
      let withImageData = await getSkuImages(allSkuIds, roResponse);
      let combineData = {
        page_info: roResponse.page_info,
        releaseorder_list: withImageData
      };
      const updatedRoList = combineData?.releaseorder_list?.map((ro: any) => ({ ...ro, key: ro?.ro_number }));
      setOpenOrderListing({ ...combineData, releaseorder_list: updatedRoList });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const onExpand = (expanded: boolean, record: any) => {
    setExpandedRowKeys(prevState =>
      expanded ? [...prevState, record.ro_number] : prevState.filter(key => key !== record.ro_number)
    );
  };

  const expandedRowRender = (data: any) => {
    const updatedData = data.fo_details.map((order: any) => ({
      ...order,
      item_list: order.item_list.map((item: any) => ({
        ...item,
        fo_number: order.fo_number,
        order_number: order.order_number,
        order_start_trans_datetime: order?.order_start_trans_datetime
      }))
    }));

    const itemList = updatedData.flatMap((order: any) => order.item_list);

    return (
      <div className="p-2">
        <Table
          className="mt-2"
          bordered
          columns={childRendererColumns}
          dataSource={itemList || []}
          pagination={false}
          scroll={{ x: 1000, y: 200 }}
        />
      </div>
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], data: any) => {
    setSelectedOrderList(data);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
  };

  const handleOnPageChange = (currentPage: any, pageSize: any) => {
    setPageControl({
      currentPage,
      pageSize
    });
    setSearchParams(prev => {
      prev.set('page_number', currentPage + '');
      prev.set('page_size', pageSize + '');
      return prev;
    });
    getOpenOrderList();
  };

  const handleOnClear = () => {
    manifestForm.resetFields();
    getOpenOrderList();
  };

  const handleOnAcceptSelected = async () => {
    setLoading(true);
    try {
      const requests = selectedOrderList?.map(async (item: any) => {
        const { business_group_id, tenant_id, ro_number } = item;
        const request = {
          business_group_id,
          tenant_id,
          ro_number,
          status: 'ACCEPTED',
          status_datetime: new Date().toISOString()
        };
        const header = {
          'x-idempotent-id': generateUUIDV4()
        };
        return handleOnBulkAcceptOrders(request, header);
      });
      await Promise.all(requests);
      displaySuccessNotification({ message: 'Items Accepted!' });
      setExpandedRowKeys([]);
    } catch (error) {
      console.error('Error in handleOnAcceptSelected:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnBulkAcceptOrders = async (request: any, header: any) => {
    try {
      const { errors } = await orderService.bulkhandleRoStatusChange(request, header);
      if (_.isEmpty(errors)) {
        await getOpenOrderList();
      } else {
        displayErrorNotifications(errors);
        throw new Error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error in handleOnBulkAcceptOrders:', error);
      throw error;
    }
  };

  const genertePickLict = () => {
    if (openOrderListing?.releaseorder_list?.length < 1 || selectedRowKeys?.length < 1) {
      return true;
    }
    return false;
  };

  const closeReasonModal = () => {
    manifestForm.resetFields();
    setReasonModal(false);
  };

  const handleOnBulkReject = async (request: any, header: any) => {
    try {
      const { errors } = await orderService.bulkhandleRoStatusChange(request, header);
      if (_.isEmpty(errors)) {
        await getOpenOrderList();
      } else {
        displayErrorNotifications(errors);
        throw new Error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error in handleOnBulkAcceptOrders:', error);
      throw error;
    }
  };

  const handleOnRejectSelected = async () => {
    setLoading(true);
    try {
      const { reason } = manifestForm.getFieldsValue();
      const requests = selectedOrderList?.map(async (item: any) => {
        const { business_group_id, tenant_id, ro_number } = item;
        const request = {
          business_group_id,
          tenant_id,
          ro_number,
          status: 'REJECTED',
          reason: reason,
          status_datetime: new Date().toISOString()
        };
        const header = {
          'x-idempotent-id': generateUUIDV4()
        };
        return handleOnBulkReject(request, header);
      });
      await Promise.all(requests);
      displaySuccessNotification({ message: 'Items Rejected!' });
      setExpandedRowKeys([]);
      closeReasonModal();
    } catch (error) {
      console.error('Error in handleOnAcceptSelected:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={manifestForm} onFinish={getOpenOrderList}>
          <Card>
            <Row justify={'space-between'} className="mb-4" gutter={12}>
              <Col xs={12} md={6}>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  {t('openOrders')}
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={6}>
                <Form.Item name="ro_number" label={t('roNumber')}>
                  <Input placeholder={t('roNumber')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="item_id" label={t('itemID')}>
                  <Input placeholder={t('itemID')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="order_number" label={t('orderNumber')}>
                  <Input placeholder={t('orderNumber')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="order_date_range" label={t('orderDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={6}>
                <Form.Item name="release_date_range" label={t('releaseDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="pb-4" gutter={12}>
              <Col xs={12} md={3}>
                <Button type="primary" htmlType="submit" size="large" block>
                  <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button size="large" onClick={handleOnClear} block>
                  <BoldButtonLabel labelText={t('clear')} />
                </Button>
              </Col>
            </Row>
            <section>
              <div className="flex justify-end mb-2.5 gap-2.5">
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={handleOnAcceptSelected}
                  disabled={genertePickLict()}
                >
                  {t('acceptAll')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setReasonModal(true);
                  }}
                  disabled={genertePickLict()}
                >
                  {t('rejectSelected')}
                </Button>
              </div>
              <Table
                rowSelection={permission ? rowSelection : undefined}
                loading={false}
                className="mt-4"
                expandable={{
                  expandedRowRender,
                  expandedRowKeys,
                  onExpand,
                  rowExpandable: record => record?.ro_number
                }}
                pagination={{
                  current: pageControl?.currentPage,
                  total: openOrderListing?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: handleOnPageChange
                }}
                locale={{
                  emptyText: t('noData')
                }}
                bordered
                dataSource={openOrderListing?.releaseorder_list || []}
                columns={columns}
                scroll={{ x: 1000 }}
              />
            </section>
          </Card>
          <Modal
            centered
            open={reasonModal}
            onCancel={closeReasonModal}
            width={800}
            footer={false}
            title={
              <div className="flex">
                <Typography.Title level={4} className={'mt-3'}>
                  {'Reason'}
                </Typography.Title>
              </div>
            }
          >
            <div className="flex flex-col gap-2">
              <Form.Item
                name={'reason'}
                rules={[
                  {
                    required: true,
                    message: t('fieldIsRequiredFunction', { name: 'Reason' }).replace('{name}', 'Reason')
                  }
                ]}
              >
                <Input.TextArea placeholder={t('enterRejectionReason')} />
              </Form.Item>
              <div className="flex justify-end mb-2.5 gap-2.5">
                <Button
                  onClick={async () => {
                    try {
                      await manifestForm.validateFields(['reason']);
                      handleOnRejectSelected();
                    } catch (err) {
                      console.log('Reason field', err);
                    }
                  }}
                  type="primary"
                >
                  <BoldButtonLabel labelText="Confirm" />
                </Button>
                <Button onClick={closeReasonModal}>
                  <BoldButtonLabel labelText="Cancel" />
                </Button>
              </div>
            </div>
          </Modal>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default OpenOrderListing;
