import { Button, Card, Col, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import { getAccess } from '../../../constants/common-constants';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { manifestService } from '../../../modules/nebula/services';
import { useLoader } from '../../../stores/use-loader';

interface IManifestDetails {
  productOf: string;
}

const ManifestDetails: React.FunctionComponent<IManifestDetails> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { manifestNumber } = useParams();

  const [manifestDetailData, setManifestDetailData] = React.useState({} as any);
  // const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: ColumnsType<any> = [
    {
      title: t('shipmentNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.shipment_no}</Typography.Text>;
      }
    },
    {
      title: t('invoiceNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.invoice_no}</Typography.Text>;
      }
    },
    {
      title: t('awbNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.awb_no}</Typography.Text>;
      }
    },
    // {
    //   title: t('invoiceDate'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <Typography.Text>{record?.invoice_no}</Typography.Text>;
    //   }
    // },
    {
      title: t('containerNumber'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.container_number}</Typography.Text>;
      }
    },
    {
      title: t('containerType'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.container_type}</Typography.Text>;
      }
    },
    {
      title: t('courierPartner'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{manifestDetailData?.carrier_dtls?.carrier_name}</Typography.Text>;
      }
    },
    {
      title: t('action'),
      align: 'center',
      width: 100,
      render(value, record, index) {
        return (
          <>
            <Typography.Text className="text-[#f00] cursor-pointer" onClick={() => handleOnRemove(record)}>
              {'Remove'}
            </Typography.Text>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = async () => {
    setLoading(true);
    const { data, errors } = await manifestService.getManifestDataById(manifestNumber);
    if (_.isEmpty(errors)) {
      setManifestDetailData(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnPageChange = (currentPage: any, pageSize: any) => {
    setPageControl({
      currentPage,
      pageSize
    });
    setSearchParams(prev => {
      prev.set('page_number', currentPage + '');
      prev.set('page_size', pageSize + '');
      return prev;
    });
    loadIntialData();
  };

  const handleOnCloseManifest = async () => {
    setLoading(true);
    const { errors } = await manifestService.closeManifest(manifestNumber);
    if (_.isEmpty(errors)) {
      handleOnGoBack();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnGoBack = () => {
    const backUrl = searchParams.get('backUrl');
    if (backUrl) navigate(backUrl);
    else navigate(-1);
  };

  const handleOnRemove = async (record: any) => {
    setLoading(true);
    let manifest_id = manifestNumber;
    let container_id = record?.id;
    const { errors } = await manifestService.removeContainer(manifest_id, container_id);
    if (_.isEmpty(errors)) {
      loadIntialData();
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4 mt-4" gutter={12}>
            <Col xs={12} md={12}>
              <Typography.Title level={4} className="flex text-[#2e2a5b]">
                {'ManifestDetails - '}
                {manifestNumber}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section>
            <Table
              loading={false}
              // rowSelection={permission ? rowSelection : undefined}
              className="mt-8"
              pagination={{
                current: pageControl?.currentPage,
                total: 0, //openOrderListing?.page_info?.total_pages * pageControl?.pageSize ||
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '10', '20', '50', '100'],
                onChange: handleOnPageChange
              }}
              locale={{
                emptyText: <div className="p-8">{t('noData')}</div>
              }}
              bordered
              dataSource={manifestDetailData?.containers || []}
              columns={columns}
              scroll={{ x: 1000 }}
            />
          </section>
          <Row gutter={12} className="mt-4">
            <Col xs={24} md={8} lg={6}>
              <Button
                block
                size="large"
                onClick={() => {
                  handleOnGoBack();
                }}
              >
                <BoldButtonLabel labelText={t('back')} />{' '}
              </Button>
            </Col>
            {manifestDetailData?.status !== 'CLOSED' && (
              <Col xs={24} md={8} lg={6}>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={() => {
                    handleOnCloseManifest();
                  }}
                >
                  {t('closeManifest')}
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default ManifestDetails;
