import * as fileHelpers from './file.helpers';
import * as urlHelpers from './url.helpers';
import * as validationHelpers from './validation.helpers';

export const convertQueryStringToObj = (queryString: string) => {
  return Object.fromEntries(new URLSearchParams(queryString));
};

export const deleteUndefinedValuesFromObject = (obj: Record<any, any>): Record<any, any> => {
  const filteredObj: Record<any, any> = {};
  Object.keys(obj).forEach((key: string) => {
    if (obj[key] !== undefined && obj[key] !== '' && obj[key] !== null) {
      filteredObj[key] = obj[key];
    }
  });

  return filteredObj;
};

function convertObjectKeysSnakeToCamel(obj: Record<string, any>): Record<string, any> {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      newObj[camelKey] = obj[key];
    }
  }

  return newObj;
}
function getCurrencySymbol(currencyCode: string): string {
  const currencySymbols: { [key: string]: string } = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound
    JPY: '¥', // Japanese Yen
    INR: '₹', // Indian Rupee
    AUD: 'A$', // Australian Dollar
    CAD: 'C$', // Canadian Dollar
    CHF: 'CHF', // Swiss Franc
    CNY: '¥', // Chinese Yuan
    HKD: 'HK$', // Hong Kong Dollar
    NZD: 'NZ$', // New Zealand Dollar
    SEK: 'kr', // Swedish Krona
    KRW: '₩', // South Korean Won
    SGD: 'S$', // Singapore Dollar
    MXN: 'Mex$', // Mexican Peso
    RUB: '₽', // Russian Ruble
    ZAR: 'R', // South African Rand
    TRY: '₺', // Turkish Lira
    BRL: 'R$', // Brazilian Real
    CLP: 'Ch$' // Chilean Peso
  };

  // Return the symbol or the original code if not found
  return currencySymbols[currencyCode] || currencyCode;
}

const objectHelpers = {
  deleteUndefinedValuesFromObject,
  convertQueryStringToObj,
  convertObjectKeysSnakeToCamel,
  getCurrencySymbol
};

export { objectHelpers, fileHelpers, urlHelpers, validationHelpers };
