import { Button, Col, DatePicker, Form, Input, Row, Select, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import { getAccess, readableString } from '../../../constants/common-constants';
import { objectHelpers, urlHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications } from '../../../helpers/toast.helpers';
import { manifestService } from '../../../modules/nebula/services';
import { useLoader } from '../../../stores/use-loader';

interface IManifestTenents {
  tabKey: string;
  productOf: string;
  carrier: any;
  active: boolean;
}

const ManifestTenents: React.FC<IManifestTenents> = ({ tabKey, productOf, carrier, active }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [manifestForm] = Form.useForm();
  const navigate = useNavigate();

  const [manifestListingData, setManifestListingData] = React.useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: ColumnsType<any> = [
    {
      title: t('mainfestNumber'),
      align: 'center',
      render(value, record, index) {
        return (
          <Tooltip placement={'top'} title={t('viewDetails')}>
            <Typography.Text
              className="text-[#1200ff] underline cursor-pointer font-[400]"
              onClick={() => {
                handleOnClickManifestNumber(record);
              }}
            >
              {record?.manifest_id}
            </Typography.Text>
          </Tooltip>
        );
      }
    },
    {
      title: t('status'),
      align: 'center',
      render(value, record, index) {
        let statusData = readableString(record?.status);
        return <Typography.Text>{statusData}</Typography.Text>;
      }
    },
    {
      title: t('manifestCreatedDate'),
      align: 'center',
      render(value, record, index) {
        const date = dayjs(record.created_datetime).format('YYYY-MM-DD');
        return <Typography.Text>{date}</Typography.Text>;
      }
    },
    {
      title: t('courierPartner'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.carrier_dtls?.carrier_name}</Typography.Text>;
      }
    },
    {
      title: t('numOfPackages'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.containers?.length}</Typography.Text>;
      }
    }
    // {
    //   title: t('totalQty'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return <Typography.Text>{}</Typography.Text>;
    //   }
    // }
    // {
    //   title: t('action'),
    //   align: 'center',
    //   render(value, record, index) {
    //     return (
    //       <Tooltip
    //         placement={'bottom'}
    //         title={t('goToManifestation', { ID: record?.manifest_id }).replace('{ID}', record?.manifest_id)}
    //       >
    //         <Typography.Text
    //           className="text-[#1200ff] cursor-pointer "
    //           onClick={() => {
    //             handleOnClickview(record);
    //           }}
    //         >
    //           {`View`}
    //         </Typography.Text>
    //       </Tooltip>
    //     );
    //   }
    // }
  ];

  React.useEffect(() => {
    if (active) {
      loadIntialData();
    }
  }, [tabKey, active]);

  const loadIntialData = () => {
    manifestForm.setFieldsValue({
      status: 'OPEN'
    });
    getManifestData();
  };

  const getManifestData = async () => {
    // setLoading(true);
    const page_size = parseInt(searchParams.get('page_size') || '') || 10;
    const page_number = parseInt(searchParams.get('page_number') || '') || 1;
    const params = {
      offset: page_number - 1,
      limit: page_size,
      'carrier-id': carrier.id,
      ...manifestForm.getFieldsValue()
    };
    const queryParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(queryParams);
    const { data, errors } = await manifestService.getManifestData(queryParams);
    if (_.isEmpty(errors)) {
      setManifestListingData(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleOnPageChange = (currentPage: any, pageSize: any) => {
    setPageControl({
      currentPage,
      pageSize
    });
    setSearchParams(prev => {
      prev.set('page_number', currentPage + '');
      prev.set('page_size', pageSize + '');
      return prev;
    });
    getManifestData();
  };

  const handleOnClickManifestNumber = (record: any) => {
    let data = searchParams;
    const backUrl = urlHelpers.getBackUrl(data);
    let manifestNumber = record?.manifest_id;
    navigate(`/nebula/order-fulfillment/shopify/manifest/manifest-detail/${manifestNumber}?backUrl=${backUrl}`);
  };

  // const handleOnClickview = (record: any) => {
  //   let path = `/nebula/order-fulfillment/shopify/manifest/manifest-station?manifest-id=${record?.manifest_id}`;
  //   navigate(path);
  // };

  return (
    <Form
      layout="vertical"
      form={manifestForm}
      onFinish={() => {
        getManifestData();
      }}
    >
      <Row gutter={12} className="mt-4">
        <Col xs={24} md={6}>
          <Form.Item name="status" label={t('status')}>
            <Select
              placeholder={t('status')}
              size="large"
              options={[
                { label: t('open'), value: 'OPEN' },
                { label: t('close'), value: 'CLOSED' }
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name="manifest-id" label={t('mainfestNumber')}>
            <Input placeholder={t('mainfestNumber')} size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name="order_date_range" label={t('manifestCreatedDate')}>
            <DatePicker.RangePicker
              size="large"
              placeholder={[t('startDate'), t('endDate')]}
              style={{ borderRadius: '0' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="pb-4" gutter={12}>
        <Col xs={12} md={3}>
          <Button type="primary" htmlType="submit" size="large" block>
            <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
          </Button>
        </Col>
        <Col xs={12} md={3}>
          <Button
            size="large"
            onClick={() => {
              manifestForm.resetFields();
              getManifestData();
            }}
            block
          >
            <BoldButtonLabel labelText={t('clear')} />
          </Button>
        </Col>
      </Row>
      <section>
        <Table
          loading={false}
          className="mt-4"
          pagination={{
            current: pageControl?.currentPage,
            total: manifestListingData?.total_pages * pageControl?.pageSize || 0, //openOrderListing?.page_info?.total_pages * pageControl?.pageSize ||
            pageSize: pageControl?.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['1', '10', '20', '50', '100'],
            onChange: handleOnPageChange
          }}
          locale={{
            emptyText: <div className="p-8">{t('noData')}</div>
          }}
          bordered
          dataSource={manifestListingData?.manifests || []}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </section>
    </Form>
  );
};

export default ManifestTenents;
