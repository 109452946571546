import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import TenantInfo from '../../components/TenantIdInfo';
import { getAccess, readableString } from '../../constants/common-constants';
import { objectHelpers } from '../../helpers';
import {
  formatDate,
  resetISOTimeStampTimeToZeroIST,
  resetISOTimeStampTimeToZeroISTCurrent
} from '../../helpers/date.helpers';
import { fetchReqData } from '../../helpers/file.helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService } from '../../modules/nebula/services';
import { useLoader } from '../../stores/use-loader';

interface IAdvanceOrderSearch {
  productOf: string;
}

const AdvanceOrderSearch: React.FC<IAdvanceOrderSearch> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [orderSearchForm] = Form.useForm();

  const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
  const [openOrderListing, setOpenOrderListing] = React.useState({} as any);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  //zustand declare
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const orderStatuses = [
    { label: 'Created', value: 'CREATED' },
    { label: 'Arrived At Store', value: 'ARRIVED_AT_STORE' },
    { label: 'Partially Picked Up', value: 'PARTIALLY_PICKED_UP' },
    { label: 'Packed', value: 'PACKED' },
    { label: 'Out For Delivery', value: 'OUT_FOR_DELIVERY' },
    { label: 'Partially Ready For Pickup', value: 'PARTIALLY_READY_FOR_PICKUP' },
    { label: 'Partially Ready To Ship', value: 'PARTIALLY_READY_TO_SHIP' },
    { label: 'Partially Out For Pickup', value: 'PARTIALLY_OUT_FOR_PICKUP' },
    { label: 'Terminated', value: 'TERMINATED' },
    { label: 'Partially Pickup Processing', value: 'PARTIALLY_PICKUP_PROCESSING' },
    { label: 'Out For Pickup', value: 'OUT_FOR_PICKUP' },
    { label: 'Pickup Processing', value: 'PICKUP_PROCESSING' },
    { label: 'Partially Received', value: 'PARTIALLY_RECEIVED' },
    { label: 'Released', value: 'RELEASED' },
    { label: 'Partially Picked', value: 'PARTIALLY_PICKED' },
    { label: 'Partially In Transit', value: 'PARTIALLY_IN_TRANSIT' },
    { label: 'Received', value: 'RECEIVED' },
    { label: 'In Negotiation', value: 'IN_NEGOTIATION' },
    { label: 'Backorder From IMS', value: 'BACKORDER_FROM_IMS' },
    { label: 'Partially Ready To Pick', value: 'PARTIALLY_READY_TO_PICK' },
    { label: 'Refunded', value: 'REFUNDED' },
    { label: 'Hold', value: 'HOLD' },
    { label: 'Ready To Pick', value: 'READY_TO_PICK' },
    { label: 'Partially Ready To Pack', value: 'PARTIALLY_READY_TO_PACK' },
    { label: 'Carrier Booked', value: 'CARRIER_BOOKED' },
    { label: 'Ready To Ship', value: 'READY_TO_SHIP' },
    { label: 'Partially Out For Delivery', value: 'PARTIALLY_OUT_FOR_DELIVERY' },
    { label: 'Refund In Progress', value: 'REFUND_IN_PROGRESS' },
    { label: 'In Transit', value: 'IN_TRANSIT' },
    { label: 'Partially Packed', value: 'PARTIALLY_PACKED' },
    { label: 'Shipped', value: 'SHIPPED' },
    { label: 'Delivered', value: 'DELIVERED' },
    { label: 'Line Reshipped', value: 'LINE_RESHIPPED' },
    { label: 'Arrived At WH', value: 'ARRIVED_AT_WH' },
    { label: 'Partially Shipped', value: 'PARTIALLY_SHIPPED' },
    { label: 'Shipment Processing', value: 'SHIPMENT_PROCESSING' },
    { label: 'Awaiting Release', value: 'AWAITING_RELEASE' },
    { label: 'Picked Up', value: 'PICKED_UP' },
    { label: 'Cancelled', value: 'CANCELLED' },
    { label: 'Release Accepted', value: 'RELEASE_ACCEPTED' },
    { label: 'Backorder From Node', value: 'BACKORDER_FROM_NODE' },
    { label: 'Picked', value: 'PICKED' },
    { label: 'Partially Release Accepted', value: 'PARTIALLY_RELEASE_ACCEPTED' },
    { label: 'Ready For Pickup', value: 'READY_FOR_PICKUP' },
    { label: 'Order Line Hold', value: 'ORDER_LINE_HOLD' },
    { label: 'Partially Delivered', value: 'PARTIALLY_DELIVERED' },
    { label: 'Picklist Generated', value: 'PICKLIST_GENERATED' },
    { label: 'Handed Over To Courier', value: 'HANDED_OVER_TO_COURIER' },
    { label: 'Delivery Attempted', value: 'DELIVERY_ATTEMPTED' },
    { label: 'Partially Released', value: 'PARTIALLY_RELEASED' },
    { label: 'Ready To Pack', value: 'READY_TO_PACK' },
    { label: 'Draft Order', value: 'DRAFT_ORDER' }
  ];

  const columns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      width: 50,
      render(value, record, index) {
        let data = record?.fo_details?.length > 0 ? record.fo_details[0].order_number : '-';
        return <Typography.Text>{data}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      width: 150,
      render(value, record, index) {
        let date =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('DD/MM/YYYY')
              : '-'
            : '-';
        return (
          <Typography.Text>
            <>{date}</>
          </Typography.Text>
        );
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      width: 200,
      render(value, record, index) {
        return <Typography.Text>{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('releaseDateM'),
      align: 'center',
      render(value, record, index) {
        let date = record?.created_datetime ? dayjs(record?.created_datetime).format('DD/MM/YYYY') : '-';
        // let time = record?.created_datetime ? dayjs(record?.created_datetime).format('HH:mm:ss') : '-';
        return (
          <Typography.Text>
            <>{date}</>
          </Typography.Text>
        );
      }
    },
    {
      title: 'Requested Ship Date',
      align: 'center',
      render: (_, record) => {
        let dateData = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date')
          : '';
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: t('status'),
      align: 'center',
      render: (_, record) => {
        return <>{t(record?.status_description)}</>;
      }
    },
    {
      title: t('Ship Node'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.node_name}</Typography.Text>;
      }
    },
    {
      title: t('shippingMethod'),
      align: 'center',
      render(value, record, index) {
        let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{deliveryMethod}</Typography.Text>;
      }
    },
    {
      title: 'Requested Delivery Date',
      align: 'center',
      render: (_, record) => {
        const promiseDeliveryDate = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date')
          : '';
        return <>{promiseDeliveryDate ? `${formatDate(promiseDeliveryDate?.date_time_stamp)}` : ''}</>;
      }
    },
    {
      title: t('orderedQtyM'),
      align: 'center',
      width: 50,
      render(value, record, index) {
        let quantity = 0;
        record?.fo_details.forEach((fo: any) => {
          const qty = fo.item_list?.reduce((acc: any, curr: any) => {
            return acc + curr.original_quantity?.number_of_units;
          }, 0);
          quantity += qty;
        });
        return <Typography.Text>{quantity}</Typography.Text>;
      }
    }
  ];

  const childRendererColumns: ColumnsType<any> = [
    {
      title: t('itemID'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.sku_id}</Typography.Text>;
      }
    },
    {
      title: t('itemDescription'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.skuDescription}</Typography.Text>;
      }
    },
    {
      title: t('itemImage'),
      align: 'center',
      render(value, record, index) {
        let url = record?.skuImageURL || '';
        return (
          <>
            <img
              src={url}
              alt="NOIMAGE"
              style={{
                height: '65px',
                width: '60px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            />
          </>
        );
      }
    },
    {
      title: t('quantity'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.original_quantity?.number_of_units}</Typography.Text>;
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    getAdvanceSearchData();
  };

  const getSkuImages = async (skuArray: any, listingData: any) => {
    const payload = {
      sku_ids: skuArray
    };
    const { data, errors } = await orderService.getBulkImages(payload);
    if (_.isEmpty(errors)) {
      const skuImageMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.image_urls?.[0] || ''];
        })
      );
      const skuDescriptionMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.description];
        })
      );
      const updatedData1 = listingData?.releaseorder_list?.map((obj: any) => ({
        ...obj,
        fo_details: obj?.fo_details?.map((detail: any) => ({
          ...detail,
          item_list: detail?.item_list?.map((item: any) => {
            let obj = {
              ...item,
              skuImageURL: skuImageMap[item.sku_id] || '',
              skuDescription: skuDescriptionMap[item.sku_id]
            };
            return obj;
          })
        }))
      }));
      return updatedData1;
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getAdvanceSearchData = async () => {
    setLoading(true);
    const page_size = parseInt(searchParams.get('page_size') || '') || 10;
    const page_number = parseInt(searchParams.get('page_number') || '') || 1;
    const { date_range } = orderSearchForm.getFieldsValue();
    const params = {
      offset: page_number - 1,
      limit: page_size,
      //   status: 'RELEASED',
      channel_id: 'shopify',
      ...orderSearchForm.getFieldsValue(),
      created_after: date_range ? resetISOTimeStampTimeToZeroIST(date_range[0]) : undefined,
      created_before: date_range ? resetISOTimeStampTimeToZeroISTCurrent(date_range[1]) : undefined,
      date_range: undefined
    };
    const queryParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(queryParams);
    const { data, errors } = await orderService.getOrderFulfillment(queryParams);
    if (_.isEmpty(errors)) {
      const roResponse: any = data;
      const allSkuIds = roResponse?.releaseorder_list?.flatMap((obj: any) =>
        obj.fo_details.flatMap((detail: any) => detail.item_list.map((item: any) => item.sku_id))
      );
      let withImageData = await getSkuImages(allSkuIds, roResponse);
      let combineData = {
        page_info: roResponse.page_info,
        releaseorder_list: withImageData
      };
      const updatedRoList = combineData?.releaseorder_list?.map((ro: any) => ({ ...ro, key: ro?.ro_number }));
      setOpenOrderListing({ ...combineData, releaseorder_list: updatedRoList });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const expandedRowRender = (data: any) => {
    const updatedData = data.fo_details.map((order: any) => ({
      ...order,
      item_list: order.item_list.map((item: any) => ({
        ...item,
        fo_number: order.fo_number,
        order_number: order.order_number,
        order_start_trans_datetime: order?.order_start_trans_datetime
      }))
    }));

    const itemList = updatedData.flatMap((order: any) => order.item_list);

    return (
      <div className="p-2">
        <Table
          className="mt-2"
          bordered
          columns={childRendererColumns}
          dataSource={itemList || []}
          pagination={false}
          scroll={{ x: 1000, y: 200 }}
        />
      </div>
    );
  };

  const onExpand = (expanded: boolean, record: any) => {
    setExpandedRowKeys(prevState =>
      expanded ? [...prevState, record.ro_number] : prevState.filter(key => key !== record.ro_number)
    );
  };

  const handleOnPageChange = (currentPage: any, pageSize: any) => {
    setPageControl({
      currentPage,
      pageSize
    });
    setSearchParams(prev => {
      prev.set('page_number', currentPage + '');
      prev.set('page_size', pageSize + '');
      return prev;
    });
    getAdvanceSearchData();
  };

  const handleOnClear = () => {
    orderSearchForm.resetFields();
    getAdvanceSearchData();
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={orderSearchForm} onFinish={getAdvanceSearchData}>
          <Card>
            <Row justify={'space-between'} className="mb-4" gutter={12}>
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  {'Orders'}
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <Row gutter={12} className="mt-8">
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="order_number" label={t('customerOrderNum')}>
                  <Input size="large" placeholder={t('searchOrderNumber')} />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item name="ro_number" label={t('roNumber')}>
                  <Input size="large" placeholder={t('searchRoNumber')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name={'delivery_method'} label={t('deliveryOption')}>
                  <Select
                    size="large"
                    placeholder={t('selectDeliveryOption')}
                    allowClear
                    options={[
                      { label: t('pickUp'), value: 'PICK_UP' },
                      { label: t('homeDelivery'), value: 'HOME_DELIVERY' }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name="date_range" label={t('orderDate')}>
                  <DatePicker.RangePicker size="large" style={{ borderRadius: '0' }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item name={'status'} label={t('status')}>
                  <Select
                    size="large"
                    showSearch
                    placeholder={t('selectDeliveryOption')}
                    allowClear
                    options={orderStatuses}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} className="mt-4">
              <Col xs={12} md={3}>
                <Button htmlType="submit" size="large" type="primary" block>
                  <BoldButtonLabel labelText={t('search')} />
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button size="large" onClick={handleOnClear} block>
                  <BoldButtonLabel labelText={t('clear')} />
                </Button>
              </Col>
            </Row>

            <section className="mt-8">
              <Table
                // rowSelection={permission ? rowSelection : undefined}
                loading={false}
                className="mt-4"
                expandable={{
                  expandedRowRender,
                  expandedRowKeys,
                  onExpand,
                  rowExpandable: record => record?.ro_number
                }}
                pagination={{
                  current: pageControl?.currentPage,
                  total: openOrderListing?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: handleOnPageChange
                }}
                locale={{
                  emptyText: t('noData')
                }}
                bordered
                dataSource={openOrderListing?.releaseorder_list || []}
                columns={columns}
                scroll={{ x: 1000 }}
              />
            </section>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default AdvanceOrderSearch;
