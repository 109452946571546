import { Card, Table } from 'antd';
import * as React from 'react';
import FieldDisplay from '../../../../components/FieldDisplay';
import { priceHelpers } from '../../../../data/intl-data';
import { useLocale } from '../../../../stores/use-locale';
import { useTranslation } from 'react-i18next';

interface IItemLevelViewProps { 
  itemInfo: any;
}

const ItemLevelView: React.FunctionComponent<IItemLevelViewProps> = ({ itemInfo }) => {
  const { baseCurrency, userCurrency } = useLocale();
  const { t } = useTranslation();
  return (
    <>
      {' '}
      <section>
        <Card className='mb-5'>
          <div className="my-auto font-bold text-base">{t('skuInformation')}</div>
          <div className="flex gap-4 flex-wrap mt-2">
            <div>
              <section className="px-2 flex flex-col">
                <FieldDisplay label={t('articleId')} value={itemInfo?.article_id} />
                <FieldDisplay label={t('skuId')} value={itemInfo?.sku_id} />
                <FieldDisplay label={t('productName')} value={itemInfo?.orderDetailsItems?.product_name} />
              </section>
            </div>
            <div>
              <section className="px-2 flex flex-col">
                <FieldDisplay label={t('productType')} value={itemInfo?.orderDetailsItems?.product_type} />
                <FieldDisplay label={t('coLineId')} value={itemInfo?.co_line_id} />
              </section>
            </div>
          </div>
        </Card>

        <Card className='mb-5'>
          <div className="font-bold text-base">{t('priceInfo')}</div>
          <div className="flex gap-4 flex-wrap mt-2">
            {/* <section className="px-2 flex flex-col">
              <FieldDisplay
                label="Retail Price"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.retail_price,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={itemInfo?.retail_price}
              />
            </section> */}
            {/* <section className="px-2 flex flex-col">
              <FieldDisplay
                label="List Price"
                // value={priceHelpers.formatPriceToLocaleCurrency({
                //   amount: itemInfo?.list_price,
                //   baseCurrency,
                //   userCurrency
                // })}
                value={itemInfo?.list_price}
              />
            </section> */}
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label={t('unitPrice')}
                value={`$ ${itemInfo?.unit_price.toFixed(2)}`}
              />
            </section>
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label={t('grossAmount')}
                value={`$ ${itemInfo?.gross_amount.toFixed(2)}`}
              />
            </section>
          </div>

          <div className="flex gap-4 flex-wrap mt-2">
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label={t('totalDiscount')}
                value={`$ ${itemInfo?.total_discount.toFixed(2)}`}
              />
            </section>
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label={t('totalTax')}
                value={`$ ${itemInfo?.total_tax.toFixed(2)}`}
              />
            </section>
            <section className="px-2 flex flex-col">
              <FieldDisplay
                label={t('otherCharges')}
                value={`$ ${itemInfo?.other_charges.toFixed(2)}`}
              />
            </section>
          </div>

          <section className="px-2 flex flex-col mt-2">
            <span className="text-xl font-bold">
              {t('itemTotal')} - {`$ ${itemInfo?.total_item_value.toFixed(2)}`}
            </span>
          </section>
        </Card>

        <div className="mt-4 mb-2 font-bold text-base">{t('tagInfo')}</div>
        <Table
          scroll={{ x: 700 }}
          bordered
          pagination={false}
          columns={[
            {
              title: t('tagId'),
              render(value, record, index) {
                return <>{record?.tag_id}</>;
              }
            },
            {
              title: t('tagType'),
              render(value, record, index) {
                return <>{t(record?.tag_type)}</>;
              }
            },
            {
              title: t('quantity'),
              render(value, record, index) {
                return <>{record?.quantity?.number_of_units}</>;
              }
            }
          ]}
          dataSource={itemInfo?.tags}
        />

        <div className="mt-4 mb-2 font-bold text-base">{t('charges')}</div>
        <Table
          scroll={{ x: 700 }}
          bordered
          pagination={false}
          columns={[
            {
              title: t('chargeCategory'),
              render(value, record, index) {
                return <>{record?.charge_category}</>;
              }
            },
            {
              title: t('chargeName'),
              render(value, record, index) {
                return <>{record?.charge_name}</>;
              }
            },
            {
              title: t('chargeAmount'),
              render(value, record, index) {
                return (
                  <>
                    {priceHelpers.formatPriceToLocaleCurrency({
                      amount: record?.amount,
                      baseCurrency,
                      userCurrency
                    })}
                  </>
                );
              }
            },
            {
              title: t('isDiscount'),
              render(value, record, index) {
                return <>{record?.is_discount ? t('yes') : t('no')}</>;
              }
            }
          ]}
          dataSource={itemInfo?.charges}
        />

        <div className="mt-4 mb-2 font-bold text-base">{t('taxes')}</div>
        <Table
          scroll={{ x: 700 }}
          bordered
          pagination={false}
          columns={[
            {
              title: t('taxCode'),
              render(value, record, index) {
                return <>{record?.tax_code}</>;
              }
            },
            {
              title: t('taxRate'),
              render(value, record, index) {
                return <>{record?.tax_rate + '%'}</>;
              }
            },
            {
              title: t('hsnCode'),
              render(value, record, index) {
                return <>{record?.hsn_code}</>;
              }
            },
            {
              title: t('taxAmount'),
              render(value, record, index) {
                return <>${record?.price}</>;
              }
            }
          ]}
          dataSource={itemInfo?.taxes || []}
        />
      </section>
    </>
  );
};

export default ItemLevelView;
