import { Button, Card, Col, Form, Input, Row, Select, Typography } from 'antd';
import DmnModeler from 'dmn-js/lib/Modeler';
import { t } from 'i18next';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { useRewardNames } from '../hooks/use-reward-names';
import { loyaltyService } from '../services/loyalty.service';
import '../styles/custom-create-point-conversion.scoped.css';

interface ICreatePointConversionNewVersionProps {}

const CreatePointConversionNewVersion: React.FunctionComponent<ICreatePointConversionNewVersionProps> = props => {
  const { rewardNameSelectOptions } = useRewardNames();
  const [searchParams] = useSearchParams();

  let modeller: any = null;

  const modellerRef = React.useRef<any>(null);

  const { id } = useParams();

  const [ruleDetail, setRuleDetail] = React.useState({} as { xml: string; reward_id: string; name: string });
  React.useEffect(() => {
    if (_.isEmpty(ruleDetail)) fetchRuleDetail();

    if (modeller === null && ruleDetail.xml) initializeDiagram();

    return () => {
      if (modeller !== null) {
        modeller.detach();
        modeller = null;
      }
    };
  }, [ruleDetail]);

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const fetchRuleDetail = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getPointConversionDetail(id as string);

    if (_.isEmpty(errors)) {
      setRuleDetail(data);
      createForm.setFieldsValue(data);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleCreateNewVersion = async () => {
    setLoading(true);
    const { reward_id, name } = createForm.getFieldsValue();

    const { xml } = await modellerRef.current.saveXML();

    const request = {
      reward_id,
      xml,
      name
    };

    const { errors } = await loyaltyService.createPointRuleNewVersion(request, searchParams.get('id') || '');

    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: t('newVersion') });
      navigate(`/loyalty/config/point-conversion?reward_id=${reward_id}&offset=0`);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const initializeDiagram = async () => {
    modeller = new DmnModeler({
      container: '#pointConversionUpdateCanvas'
    });
    modellerRef.current = modeller;

    try {
      const { warnings } = await modeller.importXML(ruleDetail.xml);

      if (warnings.length) {
        console.log('import with warnings', warnings);
      } else {
        console.log('import successful');
      }
    } catch (err) {
      console.log('something went wrong:', err);
    }
  };

  const navigate = useNavigate();
  const [createForm] = Form.useForm();

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('createNewVersion')}
              </Typography.Title>
            </Col>
          </Row>
          <div className="flex gap-4">
            <TenantInfo />
            <Typography.Text className="text-[#898c98]">
              {t('rewardType')} - <span className="font-bold text-black">{t('points')}</span>{' '}
            </Typography.Text>
          </div>

          <section className="mt-4">
            <Form layout="vertical" form={createForm}>
              <Row gutter={12}>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="reward_id"
                    label={t('rewardName')}
                    rules={[{ message: t('thisFieldIsRequired'), required: true }]}
                  >
                    <Select
                      disabled
                      // placeholder="Select Reward Type"
                      placeholder={t('selectRewardType')}
                      size="large"
                      options={rewardNameSelectOptions}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="name"
                    rules={[
                      { message: t('thisFieldIsRequired'), required: true },
                      {
                        validator: async (_, ruleName: string) => {
                          if (/\s/.test(ruleName)) {
                            return Promise.reject(new Error(t('noSpacesAllowedInRuleName')));
                          }
                        }
                      }
                    ]}
                    label={t('conversionRuleName')}
                  >
                    <Input size="large" placeholder={t('enterRuleName')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </section>
          <div id="pointConversionUpdateCanvas" className="h-[400px]"></div>

          <Row gutter={12}>
            <Col xs={24} md={8} lg={6}>
              <Button block htmlType="submit" onClick={handleCreateNewVersion} size="large" type="primary">
                <BoldButtonLabel labelText={t('createNewVersion')} />
              </Button>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Button block size="large" onClick={() => navigate(`/loyalty/config/point-conversion`)}>
                <BoldButtonLabel labelText={t('goBack')} />
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default CreatePointConversionNewVersion;
