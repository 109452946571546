import { MANIFEST_URI, OPERATOR_URI } from '../../../config/envConfig';
import httpClient from '../../../http/http.client';

export const getManifestData = async (queryParams: any) => {
  const url = `${MANIFEST_URI}/v1/manifests`;
  const { data, errors } = await httpClient.get({ url, params: queryParams });
  return { data, errors };
};

export const getManifestDataById = async (manifest_id: any) => {
  const url = `${MANIFEST_URI}/v1/manifests/${manifest_id}`;
  const { data, errors } = await httpClient.get({ url });
  return { data, errors };
};

export const removeContainer = async (manifest_id: any, container_id: any) => {
  const url = `${MANIFEST_URI}/v1/manifests/${manifest_id}/containers/${container_id}`;
  const { data, errors } = await httpClient.delete({ url });
  return { data, errors };
};

export const closeManifest = async (manifest_id: any) => {
  const url = `${MANIFEST_URI}/v1/manifests/${manifest_id}/status?status=CLOSED`;
  const body = {
    status: 'CLOSED'
  };
  const header = {
    'Content-Type': 'application/json'
  };
  const { data, errors } = await httpClient.patch({ url, headers: header, body });
  return { data, errors };
};

export const getAllOperator = async () => {
  const url = `${OPERATOR_URI}/v1/operators`;
  const params = {
    offset: 0,
    limit: 100,
    projection: 'BASIC'
  };
  const { data, errors } = await httpClient.get({ url, params });
  return { data, errors };
};
