import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table, Typography } from 'antd';
import PrimaryLayout from '../../layouts/primary-layout';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { getAccess, readableString } from '../../constants/common-constants';
import TenantInfo from '../../components/TenantIdInfo';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import { useLoader } from '../../stores/use-loader';
import { objectHelpers } from '../../helpers';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import _ from '../../helpers/lodash';
import { orderService, productService } from '../../modules/nebula/services';
import { fetchReqData } from '../../helpers/file.helpers';
import { formatDate } from '../../helpers/date.helpers';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';

interface IReadyToPickOrders {
  productOf: string;
}

const ReadyToPickOrders: React.FunctionComponent<IReadyToPickOrders> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [searchForm] = Form.useForm();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });
  const [orderListing, setOrderListing] = React.useState({} as any);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([] as string[]);
  const [expandedRowData, setExpandedRowData] = React.useState({} as Record<string, any>);
  const [cancelQtyModal, setCancelQtyModal] = React.useState(false);
  const [cancelModalData, setCancelQtydata] = React.useState({} as any);

  const columns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      render(value, record, index) {
        let data = record?.fo_details?.length > 0 ? record.fo_details[0].order_number : '-';
        return <Typography.Text>{data}</Typography.Text>;
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text className="text-[#5e5f66]">{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      render(value, record, index) {
        let date =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('DD/MM/YYYY')
              : '-'
            : '-';
        let time =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('HH:mm:ss')
              : ''
            : '';
        return (
          <Typography.Text>
            {' '}
            <>
              {date} {time}
            </>
          </Typography.Text>
        );
      }
    },
    {
      title: t('releaseDateM'),
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'release-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Requested Ship Date',
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Requested Delivery Date',
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Ship Node',
      align: 'center',
      render: (_, record) => {
        return <>{record?.node_name || '-'}</>;
      }
    },
    {
      title: t('shippingMethod'),
      align: 'center',
      render(value, record, index) {
        let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{deliveryMethod}</Typography.Text>;
      }
    },
    {
      title: t('orderedQtyM'),
      align: 'center',
      render(value, record, index) {
        let quantity = 0;
        record?.fo_details.forEach((fo: any) => {
          const qty = fo.item_list?.reduce((acc: any, curr: any) => {
            return acc + curr.original_quantity?.number_of_units;
          }, 0);
          quantity += qty;
        });
        return <Typography.Text>{quantity}</Typography.Text>;
      }
    },

    {
      title: 'Action',
      align: 'center',
      render(value, record, index) {
        return (
          <Button type="primary" onClick={() => handleSaveIndividualShipment(record)}>
            Save
          </Button>
        );
      }
    }
  ];

  const expandedRowRender = () => {
    if (_.isEmpty(expandedRowData[expandedRowKeys[0]])) return <>{t('noItemsInShipment')}</>;
    const columns: ColumnsType<any> = [
      {
        title: t('itemID'),
        align: 'center',
        render(value, record, index) {
          return <Typography.Text>{record?.sku_id}</Typography.Text>;
        }
      },
      {
        title: t('itemDescription'),
        align: 'center',
        render(value, record, index) {
          return <Typography.Text>{record?.skuDescription}</Typography.Text>;
        }
      },
      {
        title: t('itemImage'),
        align: 'center',
        render(value, record, index) {
          let url = record?.skuImageURL || '';
          return (
            <>
              <img
                src={url}
                alt="NOIMAGE"
                style={{
                  height: '100px',
                  width: '100px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }}
              />
            </>
          );
        }
      },
      {
        title: t('orderedQtyM'),
        align: 'center',
        render(value, record, index) {
          return <Typography.Text className="text-[#5e5f66]">{record?.quantity}</Typography.Text>;
        }
      },
      {
        title: `Picked Quantity`,
        align: 'center',
        render(value, record, index) {
          //   return <Typography.Text className="text-[#5e5f66]">{'-'}</Typography.Text>;
          return (
            <InputNumber
              min={0}
              max={record.quantity}
              value={record.picked_qty}
              onChange={event =>
                handleItemLevelFieldChange(record.ro_number, record.fo_number, record.sku_id, 'picked_qty', event)
              }
            />
          );
        }
      },
      {
        title: 'Scan  Barcode',
        align: 'center',
        render(value, record, index) {
          //   return <Typography.Text className="text-[#5e5f66]">{'-'}</Typography.Text>;
          return <Input />;
        }
      },
      {
        title: 'Cancelled Quantity',
        align: 'center',
        render(value, record, index) {
          return (
            // <Button
            //   type="link"
            //   onClick={() => {
            //     setCancelQtyModal(true);
            //     setCancelQtydata(record);
            //   }}
            // >
            //   {record.cancelled_qty || 0} / {record?.quantity} Cancel
            // </Button>
            <InputNumber
              min={0}
              max={record.quantity}
              value={record.cancelled_qty}
              onChange={event =>
                handleItemLevelFieldChange(record.ro_number, record.fo_number, record.sku_id, 'cancelled_qty', event)
              }
            />
          );
        }
      },
      {
        title: 'Reason',
        align: 'center',
        render(value, record, index) {
          return (
            <TextArea
              rows={4}
              placeholder="Enter reason"
              //   maxLength={6}
              value={record.cancel_reason}
              onChange={event =>
                handleItemLevelFieldChange(
                  record.ro_number,
                  record.fo_number,
                  record.sku_id,
                  'cancel_reason',
                  event.target.value
                )
              }
            />
          );
        }
      }
    ];

    const fos = expandedRowData[expandedRowKeys[0]]?.fo_details || [];
    const fulfilInfo = expandedRowData[expandedRowKeys[0]]?.fulfilment_info || {};
    const roDetails = expandedRowData[expandedRowKeys[0]];
    const tableData: any = [];

    fos.forEach((fo: any) => {
      const { channel_id } = fo;
      fo.item_list.forEach((item: any) => {
        const { sku_id, product_desc, status_description, total_amount } = item;
        tableData.push({
          ...item,
          channel_id: channel_id,
          fo_number: fo.fo_number,
          order_number: fo.order_number,
          sku_id: sku_id,
          key: sku_id,
          description: product_desc,
          created_date: roDetails?.created_datetime,
          status: status_description,
          quantity: item.quantity?.number_of_units,
          fulfillment_type: fulfilInfo?.delivery_method,
          status_description: roDetails.status_description,
          amount: total_amount,
          order_start_trans_datetime: fo.order_start_trans_datetime,
          //   cancelled_qty: 0,
          //   cancel_reason: '',
          ro_number: expandedRowKeys[0]
        });
      });
    });

    const shipmentInfo = roDetails?.shipment_details;

    const roId = orderListing.releaseorder_list.find((ro: any) => ro.ro_number === expandedRowKeys[0])?.id || '';

    return (
      <section className="pl-8">
        <Card>
          <section className="flex items-center gap-4 my-2 justify-between">
            <div className="my-auto font-bold text-base">{t('items')}</div>
          </section>
          <Table className="mt-2" bordered columns={columns} dataSource={tableData} pagination={false} />
        </Card>
      </section>
    );
  };

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    let params = {
      ...formValues,
      offset,
      limit: pageControl?.pageSize,
      status: 'READY_TO_PICK',
      channel_id: 'shopify'
    };
    if (formValues.date_range) {
      let sign_up_date_from = formValues.date_range[0].format('YYYY-MM-DD');
      let sign_up_date_to = formValues.date_range[1].format('YYYY-MM-DD');
      delete params.date_range;
      params = { ...params, sign_up_date_from, sign_up_date_to };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);

    const { data, errors } = await orderService.fetchRoList(filteredParams);
    if (_.isEmpty(errors)) {
      const roResponse: any = data;

      const temp = roResponse?.releaseorder_list?.map((order: any) => ({
        ...order,
        fo_details: order.fo_details.map((fo: any) => ({
          ...fo,
          item_list: fo.item_list.map((item: any) => ({
            ...item,
            picked_qty: 0, // Initialize picked_qty to 0
            cancelled_qty: 0,
            cancel_reason: ''
          }))
        }))
      }));
      const allSkuIds = temp?.releaseorder_list?.flatMap((obj: any) =>
        obj.fo_details.flatMap((detail: any) => detail.item_list.map((item: any) => item.sku_id))
      );
      let withImageData = await getSkuImages(allSkuIds, roResponse);

      // let newRespImgURL = await getImageUrl(temp[0]);
      let combineData: any = {
        page_info: temp.page_info,
        // releaseorder_list: [newRespImgURL]
        releaseorder_list: withImageData
      };

      const updatedRoList = combineData.releaseorder_list.map((ro: any) => ({ ...ro, key: ro?.ro_number }));
      setOrderListing({ ...combineData, releaseorder_list: updatedRoList });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };
  const getSkuImages = async (skuArray: any, listingData: any) => {
    const payload = {
      sku_ids: skuArray
    };
    const { data, errors } = await orderService.getBulkImages(payload);
    if (_.isEmpty(errors)) {
      const skuImageMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.image_urls?.[0] || ''];
        })
      );
      const skuDescriptionMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.description];
        })
      );
      const updatedData1 = listingData?.releaseorder_list?.map((obj: any) => ({
        ...obj,
        fo_details: obj?.fo_details?.map((detail: any) => ({
          ...detail,
          item_list: detail?.item_list?.map((item: any) => {
            let obj = {
              ...item,
              skuImageURL: skuImageMap[item.sku_id] || '',
              skuDescription: skuDescriptionMap[item.sku_id]
            };
            return obj;
          })
        }))
      }));
      return updatedData1;
    } else {
      displayErrorNotifications(errors);
    }
  };

  const loadInitialData = async () => {
    await handleSearch(pageControl?.currentPage - 1);
  };

  const handleFetchShipments = async (roNumber: string, record: any) => {
    const updatedFoDetails = await Promise.all(
      record.fo_details.map(async (fo: any) => ({
        ...fo,
        item_list: await Promise.all(
          fo.item_list.map(async (item: any) => {
            try {
              const { data, errors } = await productService.getAllProductsFromCategoriesPromise([item.sku_id], 'BASIC');

              const imageUrl = data?.image_url || '';

              return { ...item, image_url: imageUrl };
            } catch (error) {
              return { ...item, image_url: '' };
            }
          })
        )
      }))
    );

    setExpandedRowData(expandedRowData => ({ ...expandedRowData, [roNumber]: record }));
  };
  const handleOnCloseAuditModal = () => {
    setCancelQtydata({});
    setCancelQtyModal(false);
  };
  const handleCancelQtySubmit = (values: { cancelQty: number; reason: string }) => {};

  const handleItemLevelFieldChange = (roNumber: string, foNumber: string, skuId: string, field: string, value: any) => {
    setOrderListing((prevOrderListing: any) => {
      const updatedReleaseOrders = prevOrderListing.releaseorder_list.map((order: any) => {
        if (order.ro_number !== roNumber) return order;

        return {
          ...order,
          fo_details: order.fo_details.map((fo: any) => {
            if (fo.fo_number !== foNumber) return fo;

            return {
              ...fo,
              item_list: fo.item_list.map((item: any) => (item.sku_id === skuId ? { ...item, [field]: value } : item))
            };
          })
        };
      });

      return { ...prevOrderListing, releaseorder_list: updatedReleaseOrders };
    });

    // const temp = expandedRowData[expandedRowKeys[0]].fo_details.map((fo: any) => {
    //   if (fo.fo_number !== foNumber) return fo;
    //   return {
    //     ...fo,
    //     item_list: fo.item_list.map((item: any) => (item.sku_id === skuId ? { ...item, [field]: value } : item))
    //   };
    // });
    // setExpandedRowData(expandedRowData => ({ ...expandedRowData, [roNumber]: temp[0] }));
    setExpandedRowData(prevData => {
      if (!prevData[roNumber]) return prevData; // Ensure row exists

      return {
        ...prevData,
        [roNumber]: {
          ...prevData[roNumber],
          fo_details: prevData[roNumber].fo_details.map((fo: any) =>
            fo.fo_number !== foNumber
              ? fo
              : {
                  ...fo,
                  item_list: fo.item_list.map((item: any) =>
                    item.sku_id === skuId ? { ...item, [field]: value } : item
                  )
                }
          )
        }
      };
    });
  };

  const handleSaveIndividualShipment = async (ro: any) => {
    let isValid = true;
    let errorMessages: { message: string }[] = [];

    ro.fo_details.forEach((fo: any) => {
      fo.item_list.forEach((item: any) => {
        const totalQuantity = item.quantity?.number_of_units;
        const pickedQty = item?.picked_qty || 0;
        const cancelledQty = item?.cancelled_qty || 0;

        if (pickedQty + cancelledQty !== totalQuantity) {
          errorMessages.push({
            message: `${item.sku_id}: Picked quantity and cancelled quantity must equal the total available quantity.`
          });
          isValid = false;
        }

        if (cancelledQty > 0 && !item.cancel_reason) {
          errorMessages.push({
            message: `${item.sku_id}: A cancellation reason is required for the entered cancelled quantity.`
          });
          isValid = false;
        }
      });
    });

    if (errorMessages.length > 0) {
      displayErrorNotifications(errorMessages);
      return;
    }

    if (isValid) {
      const body = {
        ro_number: ro.ro_number,
        lines: ro.fo_details.map((fo: any) => {
          return {
            order_number: fo.order_number,
            fo_number: fo.fo_number,
            fo_lines: fo.item_list.map((item: any) => {
              return {
                sku_id: item.sku_id,
                co_line_id: item.co_line_id,
                fulfilled_quantity: [
                  {
                    quantity: {
                      unit_size: 1,
                      unit_of_measure: 'EA',
                      number_of_units: item?.picked_qty || 0,
                      unit_fraction: 1
                    },
                    tag_id: item.tags[0].tag_id,
                    serial_id: '',
                    mark_remaining_as_backorder: false,
                    back_order_quantity: {
                      unit_size: 1,
                      unit_of_measure: 'EA',
                      number_of_units: item?.cancelled_qty || 0,
                      unit_fraction: 1
                    },
                    reason: item.cancel_reason
                  }
                ]
              };
            })
          };
        }),
        status_datetime: new Date().toISOString(),
        status: 'PICKED'
      };
      const { data, errors } = await orderService.handleRoStatusUpdate(body);
      if (_.isEmpty(errors)) {
        displaySuccessNotification({ message: t('updatedSuccessfully') });

        handleSearch(pageControl?.currentPage - 1);
      } else {
        displayErrorNotifications(errors);
      }
    }
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form
          layout="vertical"
          form={searchForm}
          onFinish={() => {
            if (pageControl.currentPage === 1) {
              handleSearch(0);
            } else {
              setPageControl(prev => ({
                ...prev,
                currentPage: 1
              }));
            }
          }}
        >
          <Card>
            <Row justify={'space-between'} className="mb-4" gutter={12}>
              <Col xs={12} md={6}>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Ready To Pick Orders
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={6}>
                <Form.Item name="burn_template_type" label={t('orderStatusM')}>
                  <Input placeholder={t('orderStatusM')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('releaseOrder')}>
                  <Input placeholder={t('releaseOrder')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('itemID')}>
                  <Input placeholder={t('itemID')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="order_number" label={t('orderNumber')}>
                  <Input placeholder={t('orderNumber')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="order_date_range" label={t('orderDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={6}>
                <Form.Item name="release_date_range" label={t('releaseDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="pb-4" gutter={12}>
              <Col xs={12} md={3}>
                <Button type="primary" htmlType="submit" size="large" block>
                  <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button size="large" onClick={() => {}} block>
                  <BoldButtonLabel labelText={t('clear')} />
                </Button>
              </Col>
              {/* <Col xs={12} md={{ span: 3, offset: 15 }}>
                <Button type="primary" htmlType="submit" size="large" block>
                  <BoldButtonLabel labelText={t('save')} />
                </Button>
              </Col> */}
            </Row>
            <section>
              <Table
                loading={false}
                className="mt-4"
                expandable={{
                  expandedRowRender,
                  expandedRowKeys,
                  async onExpand(expanded, record) {
                    if (expandedRowKeys.includes(record.ro_number)) {
                      return setExpandedRowKeys([]);
                    }
                    if (expanded) {
                      if (_.isEmpty(expandedRowData[record.ro_number])) {
                        await handleFetchShipments(record.ro_number, record);
                      }
                      setExpandedRowKeys([record.ro_number]);
                    }
                  },

                  rowExpandable: record => record?.ro_number
                }}
                pagination={{
                  current: pageControl?.currentPage,
                  total: orderListing?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: (currentPage, pageSize) =>
                    setPageControl({
                      currentPage,
                      pageSize
                    })
                }}
                locale={{
                  emptyText: t('noData')
                }}
                bordered
                dataSource={orderListing?.releaseorder_list || []}
                columns={columns}
                scroll={{ x: 1000 }}
              />
            </section>
          </Card>
        </Form>
      </div>
      {/* <Modal
        title={'Cancel Quantity'}
        width={1400}
        open={cancelQtyModal}
        footer={null}
        centered
        onCancel={handleOnCloseAuditModal}
      >
        <Card className="p-6">
          <Form
            layout="vertical"
            initialValues={{
              cancelQty: 0,
              reason: ''
            }}
            onFinish={handleCancelQtySubmit}
          >
            <Form.Item
              label="Quantity to Cancel"
              name="cancelQty"
              rules={[{ required: true, message: 'Please input the cancel quantity!' }]}
            >
              <InputNumber min={0} max={cancelModalData?.quantity} />
            </Form.Item>

            <Form.Item label="Reason" name="reason" rules={[{ required: true, message: 'Please input the reason!' }]}>
              <TextArea rows={4} placeholder="Enter reason" maxLength={6} />
            </Form.Item>

            <Button type="primary" htmlType="submit" size="large" block className="mt-4">
              <BoldButtonLabel labelText={t('save')} />
            </Button>
          </Form>
        </Card>
      </Modal> */}
    </PrimaryLayout>
  );
};
export default ReadyToPickOrders;
