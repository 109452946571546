import { CheckOutlined, PrinterOutlined, CheckSquareFilled } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import TenantInfo from '../../components/TenantIdInfo';
import { getAccess, readableString } from '../../constants/common-constants';
import { fileHelpers, objectHelpers } from '../../helpers';
import _ from '../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../helpers/toast.helpers';
import PrimaryLayout from '../../layouts/primary-layout';
import { orderService } from '../../modules/nebula/services';
import { useLoader } from '../../stores/use-loader';
import { generateUUIDV4 } from '../../helpers/uuid.helpers';
import { useAuth } from '../../stores/use-auth';
import { fetchReqData } from '../../helpers/file.helpers';
import { formatDate, resetISOTimeStampTimeToZeroIST } from '../../helpers/date.helpers';

interface IAcknowledgeOrders {
  productOf: string;
}

const AcknowledgeOrders: React.FunctionComponent<IAcknowledgeOrders> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [acknowledgeForm] = Form.useForm();

  const [generatePickListModal, setGeneratePickListModal] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<React.Key[]>([]);
  const [selectedPDFPickList, setSelectedPickList] = React.useState<React.Key[]>([]);
  const [selectedPickListWholeData, setSelectedPickListwholeData] = React.useState<React.Key[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [acknowledgeOrderListing, setAcknowledgeOrderListing] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  //zustand declare
  const { tenentName } = useAuth(({ tenentName }) => ({ tenentName }));
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));

  const columns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      render(value, record, index) {
        let data = record?.fo_details?.length > 0 ? record.fo_details[0].order_number : '-';
        return <Typography.Text>{data}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      render(value, record, index) {
        let date =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('DD/MM/YYYY')
              : '-'
            : '-';
        // let time =
        //   record.fo_details.length > 0
        //     ? record?.fo_details[0]?.order_start_trans_datetime
        //       ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('HH:mm:ss')
        //       : ''
        //     : '';
        return (
          <Typography.Text>
            {' '}
            <>{date}</>
          </Typography.Text>
        );
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('releaseDateM'),
      align: 'center',
      render(value, record, index) {
        let date = record?.created_datetime ? dayjs(record?.created_datetime).format('DD/MM/YYYY') : '-';
        // let time = record?.created_datetime ? dayjs(record?.created_datetime).format('HH:mm:ss') : '-';
        return (
          <Typography.Text>
            {' '}
            <Typography.Text>
              <>{date}</>
            </Typography.Text>
          </Typography.Text>
        );
      }
    },
    {
      title: 'Requested Ship Date',
      align: 'center',
      render: (_, record) => {
        let dateData = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date')
          : '';
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: t('Ship Node'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.node_name}</Typography.Text>;
      }
    },
    {
      title: t('shippingMethod'),
      align: 'center',
      render(value, record, index) {
        let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{deliveryMethod}</Typography.Text>;
      }
    },
    {
      title: 'Requested Delivery Date',
      align: 'center',
      render: (_, record) => {
        const promiseDeliveryDate = record?.fulfilment_info
          ? fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date')
          : '';
        return <>{promiseDeliveryDate ? `${formatDate(promiseDeliveryDate?.date_time_stamp)}` : ''}</>;
      }
    },
    {
      title: t('orderedQtyM'),
      align: 'center',
      render(value, record, index) {
        let quantity = 0;
        record?.fo_details.forEach((fo: any) => {
          const qty = fo.item_list?.reduce((acc: any, curr: any) => {
            return acc + curr.original_quantity?.number_of_units;
          }, 0);
          quantity += qty;
        });
        return <Typography.Text>{quantity}</Typography.Text>;
      }
    }
  ];

  const childRendererColumns: ColumnsType<any> = [
    {
      title: t('itemID'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.sku_id}</Typography.Text>;
      }
    },
    {
      title: t('itemDescription'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.skuDescription}</Typography.Text>;
      }
    },
    {
      title: t('itemImage'),
      align: 'center',
      render(value, record, index) {
        let url = record?.skuImageURL || '';
        return (
          <>
            <img
              src={url}
              alt="NOIMAGE"
              style={{
                height: '65px',
                width: '60px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            />
          </>
        );
      }
    },
    {
      title: t('quantity'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.original_quantity?.number_of_units}</Typography.Text>;
      }
    }
  ];

  const printColumns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.order_number}</Typography.Text>;
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('itemID'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.sku_id}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      render(value, record, index) {
        let date = record?.order_start_trans_datetime
          ? dayjs(record?.order_start_trans_datetime).format('DD/MM/YYYY')
          : '-';
        let time = record?.order_start_trans_datetime
          ? dayjs(record?.order_start_trans_datetime).format('HH:mm:ss')
          : '';
        return (
          <Typography.Text>
            {' '}
            <>
              {date} {time}
            </>
          </Typography.Text>
        );
      }
    },
    {
      title: t('itemDescription'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.skuDescription}</Typography.Text>;
      }
    },
    // {
    //   title: t('shippingMethod'),
    //   align: 'center',
    //   render(value, record, index) {
    //     let deliveryMethod = readableString(record?.delivery_method);
    //     return <Typography.Text>{deliveryMethod}</Typography.Text>;
    //   }
    // },
    {
      title: t('itemImage'),
      align: 'center',
      render(value, record, index) {
        let url = record?.skuImageURL || '';
        return (
          <>
            <img
              src={url}
              alt="NOIMAGE"
              style={{
                height: '65px',
                width: '60px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            />
          </>
        );
      }
    },
    {
      title: t('quantity'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text>{record?.original_quantity?.number_of_units}</Typography.Text>;
      }
    }
  ];

  React.useEffect(() => {
    loadIntialData();
  }, []);

  const loadIntialData = () => {
    getAcknowledgeOrderList();
  };

  const getSkuImages = async (skuArray: any, listingData: any) => {
    const payload = {
      sku_ids: skuArray
    };
    const { data, errors } = await orderService.getBulkImages(payload);
    if (_.isEmpty(errors)) {
      const skuImageMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.image_urls?.[0] || ''];
        })
      );
      const skuDescriptionMap = Object.fromEntries(
        data?.skus?.map((item: any) => {
          return [item.sku_id, item.article_basic_dtls.description];
        })
      );
      const updatedData1 = listingData?.releaseorder_list?.map((obj: any) => ({
        ...obj,
        fo_details: obj?.fo_details?.map((detail: any) => ({
          ...detail,
          item_list: detail?.item_list?.map((item: any) => {
            let obj = {
              ...item,
              skuImageURL: skuImageMap[item.sku_id] || '',
              skuDescription: skuDescriptionMap[item.sku_id]
            };
            return obj;
          })
        }))
      }));
      return updatedData1;
    } else {
      displayErrorNotifications(errors);
    }
  };

  const getAcknowledgeOrderList = async () => {
    setLoading(true);
    const page_size = parseInt(searchParams.get('page_size') || '') || 10;
    const page_number = parseInt(searchParams.get('page_number') || '') || 1;
    const { order_date_range, release_date_range } = acknowledgeForm.getFieldsValue();
    const params = {
      offset: page_number - 1,
      limit: page_size,
      status: 'RELEASE_ACCEPTED',
      channel_id: 'shopify',
      ...acknowledgeForm.getFieldsValue(),
      order_created_after: order_date_range ? resetISOTimeStampTimeToZeroIST(order_date_range[0]) : undefined,
      order_created_before: order_date_range ? resetISOTimeStampTimeToZeroIST(order_date_range[1]) : undefined,
      created_after: release_date_range ? resetISOTimeStampTimeToZeroIST(release_date_range[0]) : undefined,
      created_before: release_date_range ? resetISOTimeStampTimeToZeroIST(release_date_range[1]) : undefined,
      order_date_range: undefined,
      release_date_range: undefined
    };
    const queryParams = objectHelpers.deleteUndefinedValuesFromObject(params);
    setSearchParams(queryParams);
    const { data, errors } = await orderService.getOrderFulfillment(queryParams);
    if (_.isEmpty(errors)) {
      // Shitty code need to be written because of DEMO here, ATB for future Dev
      const roResponse: any = data;
      const allSkuIds = roResponse?.releaseorder_list?.flatMap((obj: any) =>
        obj.fo_details.flatMap((detail: any) => detail.item_list.map((item: any) => item.sku_id))
      );
      let withImageData = await getSkuImages(allSkuIds, roResponse);
      let combineData = {
        page_info: roResponse.page_info,
        releaseorder_list: withImageData
      };
      const updatedRoList = combineData?.releaseorder_list?.map((ro: any) => ({ ...ro, key: ro?.ro_number }));
      setAcknowledgeOrderListing({ ...combineData, releaseorder_list: updatedRoList });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const onExpand = (expanded: boolean, record: any) => {
    setExpandedRowKeys(prevState =>
      expanded ? [...prevState, record.ro_number] : prevState.filter(key => key !== record.ro_number)
    );
  };

  const expandedRowRender = (data: any) => {
    const updatedData = data.fo_details.map((order: any) => ({
      ...order,
      item_list: order.item_list.map((item: any) => ({
        ...item,
        fo_number: order.fo_number,
        order_number: order.order_number,
        order_start_trans_datetime: order?.order_start_trans_datetime
      }))
    }));

    const itemList = updatedData.flatMap((order: any) => order.item_list);

    return (
      <div className="p-2">
        <Table
          className="mt-2"
          bordered
          columns={childRendererColumns}
          dataSource={itemList || []}
          pagination={false}
          scroll={{ x: 500, y: 200 }}
        />
      </div>
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], data: any) => {
    setSelectedPickListwholeData(data);
    const transformedData = data.flatMap((order: any) =>
      order.fo_details.flatMap((fo: any) =>
        fo.item_list.map((item: any) => ({
          order_number: fo.order_number,
          order_start_trans_datetime: fo.order_start_trans_datetime,
          ro_number: order.ro_number,
          delivery_method: order.fulfilment_info?.delivery_method,
          sku_id: item.sku_id,
          original_quantity: item.original_quantity,
          skuImageURL: item?.skuImageURL,
          skuDescription: item?.skuDescription
        }))
      )
    );
    //REMEMBER: IF YOU ADD ANY REMEMBER TO UNCHECK and CHECK in LISTING PAGE
    setSelectedPickList(transformedData);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
  };

  const handleOnPageChange = (currentPage: any, pageSize: any) => {
    setPageControl({
      currentPage,
      pageSize
    });
    setSearchParams(prev => {
      prev.set('page_number', currentPage + '');
      prev.set('page_size', pageSize + '');
      return prev;
    });
  };

  const handleOnClear = () => {
    acknowledgeForm.resetFields();
    getAcknowledgeOrderList();
  };

  const closeAndResetModal = () => {
    setGeneratePickListModal(false);
  };

  const handleOnGeneratePickList = async () => {
    setLoading(true);
    setGeneratePickListModal(true);
    setLoading(false);
  };

  const getfulFilmentQty = (tags: any) => {
    let tagData = tags.map((item: any) => {
      return {
        tag_id: item.tag_id,
        tag_type: item.tag_type,
        quantity: item.quantity,
        mark_remaining_as_backorder: false
      };
    });

    return tagData;
  };

  const getFoLines = (foItem: any) => {
    let foData = foItem.map((item: any) => {
      return {
        co_line_id: item.co_line_id,
        sku_id: item.sku_id,
        fulfilled_quantity: getfulFilmentQty(item.tags)
      };
    });
    return foData;
  };

  const getPickList = () => {
    let data = selectedPDFPickList?.map((item: any) => {
      let obj = {
        customer_order_number: item?.order_number,
        ro_number: item?.ro_number,
        item_id: item?.sku_id,
        item_description: item?.skuDescription,
        ordered_qty: {
          unit_size: item.original_quantity.unit_size,
          unit_of_measure: item.original_quantity.unit_of_measure,
          number_of_units: item.original_quantity.number_of_units,
          unit_fraction: item.original_quantity.unit_fraction
        }
      };
      return obj;
    });
    return data;
  };

  const handleOnPrint = async () => {
    setLoading(true);
    const payload = {
      doc_type: 'PICK_LIST',
      document_format: 'PDF',
      data: {
        tenant_id: tenentName,
        supply_type_code: 'B2C',
        node_id: 'node1',
        node_name: 'test_node',
        pick_list_items: getPickList()
      }
    };
    const { data, errors } = await orderService.downloadPdfInByteStream(payload);
    if (_.isEmpty(errors)) {
      fileHelpers.triggerFileDownload({ data, fileName: 'Report', extension: '.pdf' });
      handleOnGenerate();
    } else {
      displayErrorNotifications(errors);
      setLoading(false);
    }
    // setLoading(false);
  };

  const handleOnBothPrintandGenerate = async () => {
    handleOnPrint();
  };

  const handleOnGenerate = async () => {
    // setLoading(true);
    try {
      const requests = selectedPickListWholeData?.map(async (item: any) => {
        const { business_group_id, tenant_id, ro_number } = item;
        const foDetails = item?.fo_details || [];
        const getLines = foDetails?.map((item: any) => {
          return {
            order_number: item?.order_number,
            fo_number: item?.fo_number,
            fo_lines: getFoLines(item.item_list)
          };
        });
        const request = {
          business_group_id,
          tenant_id,
          ro_number,
          lines: getLines,
          created_at: new Date().toISOString(),
          created_by: 'ui',
          status: 'READY_TO_PICK',
          status_datetime: new Date().toISOString()
        };
        const header = {
          'x-idempotent-id': generateUUIDV4()
        };
        return handleOnBulkReadyToPick(request, header);
      });
      await Promise.all(requests);
      displaySuccessNotification({ message: 'Picklist Generated!' });
      closeAndResetModal();
      setExpandedRowKeys([]);
    } catch (err) {
      console.error('Error in handleOnGenerate:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnBulkReadyToPick = async (request: any, header: any) => {
    try {
      const { errors } = await orderService.bulkhandlePickOrder(request, header);

      if (_.isEmpty(errors)) {
        await getAcknowledgeOrderList();
      } else {
        displayErrorNotifications(errors);
        throw new Error('Failed to update order status');
      }
    } catch (error) {
      console.error('Error in handleOnBulkAcceptOrders:', error);
      throw error;
    }
  };

  const genertePickLict = () => {
    if (acknowledgeOrderListing?.releaseorder_list?.length < 1 || selectedRowKeys?.length < 1) {
      return true;
    }
    return false;
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={acknowledgeForm} onFinish={getAcknowledgeOrderList}>
          <Card>
            <Row justify={'space-between'} className="mb-4" gutter={12}>
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  {t('acknowledgeOrders')}
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={6}>
                <Form.Item name="ro_number" label={t('roNumber')}>
                  <Input placeholder={t('roNumber')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('itemID')}>
                  <Input placeholder={t('itemID')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('orderNumber')}>
                  <Input placeholder={t('orderNumber')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="order_date_range" label={t('orderDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={6}>
                <Form.Item name="release_date_range" label={t('releaseDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="pb-4" gutter={12}>
              <Col xs={12} md={3}>
                <Button type="primary" htmlType="submit" size="large" block>
                  <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button size="large" onClick={handleOnClear} block>
                  <BoldButtonLabel labelText={t('clear')} />
                </Button>
              </Col>
            </Row>
            <section>
              {/* {selectedRowKeys.length > 0 && ( */}
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  onClick={handleOnGeneratePickList}
                  disabled={genertePickLict()}
                >
                  {t('generatePicklist')}
                </Button>
              </div>
              {/* )} */}
              <Table
                rowSelection={permission ? rowSelection : undefined}
                loading={false}
                className="mt-4"
                expandable={{
                  expandedRowRender,
                  expandedRowKeys,
                  onExpand,
                  rowExpandable: record => record?.ro_number
                }}
                pagination={{
                  current: pageControl?.currentPage,
                  total: acknowledgeOrderListing?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],
                  onChange: handleOnPageChange
                }}
                locale={{
                  emptyText: t('noData')
                }}
                bordered
                dataSource={acknowledgeOrderListing?.releaseorder_list || []}
                columns={columns}
                scroll={{ x: 1000 }}
              />
            </section>
          </Card>
          <Modal
            centered
            open={generatePickListModal}
            onCancel={() => {
              closeAndResetModal();
            }}
            width={1500}
            footer={false}
            title={
              <div className="flex gap-2">
                <CheckSquareFilled className="mb-2" style={{ fontSize: '28px', color: '#1890ff' }} />
                <Typography.Title level={4}>{t('selectedPickList')}</Typography.Title>
              </div>
            }
          >
            <section className="mt-4">
              <Table
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                }}
                loading={false}
                pagination={false}
                bordered
                dataSource={selectedPDFPickList || []}
                columns={printColumns}
                scroll={{ x: 1000, y: 500 }}
              />
            </section>
            <div className="mt-4 flex flex-row-reverse gap-[25px]">
              <Button
                onClick={() => {
                  closeAndResetModal();
                }}
              >
                {t('cancel')}
              </Button>
              <Button icon={<PrinterOutlined />} type="primary" onClick={handleOnBothPrintandGenerate}>
                {t('printGen')}
              </Button>

              {/* <Button icon={<PrinterOutlined />} onClick={handleOnPrint}>
                {t('PRINT')}
              </Button> */}
            </div>
          </Modal>
        </Form>
      </div>
    </PrimaryLayout>
  );
};

export default AcknowledgeOrders;
