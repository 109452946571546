import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, Typography } from 'antd';
import PrimaryLayout from '../../layouts/primary-layout';
import { useTranslation } from 'react-i18next';
import BoldButtonLabel from '../../components/BoldButtonLabel';
import { getAccess, readableString } from '../../constants/common-constants';
import TenantInfo from '../../components/TenantIdInfo';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import { useLoader } from '../../stores/use-loader';
import { objectHelpers } from '../../helpers';
import { orderService } from '../../modules/nebula/services';
import _ from 'lodash';
import { displayErrorNotifications } from '../../helpers/toast.helpers';
import dayjs from 'dayjs';
import { fetchReqData } from '../../helpers/file.helpers';
import { formatDate } from '../../helpers/date.helpers';

interface IReadyToShipOrders {
  productOf: string;
}

const ReadyToShipOrders: React.FunctionComponent<IReadyToShipOrders> = ({ productOf }) => {
  const { permission } = getAccess(productOf);
  const { t } = useTranslation();
  const [searchForm] = Form.useForm();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useLoader(({ loading, setLoading }) => ({ loading, setLoading }));
  const [orderListing, setOrderListing] = React.useState({} as any);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const page = parseInt(searchParams.get('page_number') || '1');
    const limit = parseInt(searchParams.get('page_size') || '10');
    return {
      currentPage: page > 0 ? page : 1,
      pageSize: limit > 0 ? limit : 10
    };
  });

  const columns: ColumnsType<any> = [
    {
      title: t('customerOrderNum'),
      align: 'center',
      render(value, record, index) {
        let data = record?.fo_details?.length > 0 ? record.fo_details[0].order_number : '-';
        return <Typography.Text>{data}</Typography.Text>;
      }
    },
    {
      title: t('releaseOrderNum'),
      align: 'center',
      render(value, record, index) {
        return <Typography.Text className="text-[#5e5f66]">{record?.ro_number}</Typography.Text>;
      }
    },
    {
      title: t('orderDateM'),
      align: 'center',
      render(value, record, index) {
        let date =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('DD/MM/YYYY')
              : '-'
            : '-';
        let time =
          record.fo_details.length > 0
            ? record?.fo_details[0]?.order_start_trans_datetime
              ? dayjs(record?.fo_details[0]?.order_start_trans_datetime).format('HH:mm:ss')
              : ''
            : '';
        return (
          <Typography.Text>
            {' '}
            <>
              {date} {time}
            </>
          </Typography.Text>
        );
      }
    },
    {
      title: t('releaseDateM'),
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'release-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Requested Ship Date',
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'estimated-shipping-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: 'Requested Delivery Date',
      align: 'center',
      render: (_, record) => {
        let dateData = fetchReqData(record?.fulfilment_info?.fulfillment_dates, 'date_type', 'promise-delivery-date');
        return <>{formatDate(dateData?.date_time_stamp)}</>;
      }
    },
    {
      title: t('shippingMethod'),
      align: 'center',
      render(value, record, index) {
        let deliveryMethod = readableString(record?.fulfilment_info?.delivery_method);
        return <Typography.Text>{deliveryMethod}</Typography.Text>;
      }
    },
    {
      title: t('action'),
      align: 'center',
      render(value, record, index) {
        return (
          // <Button type="primary" htmlType="submit" size="large" block>
          //   <BoldButtonLabel labelText={t('save')} />
          // </Button>
          <Link to={`/nebula/order-fulfillment/pack-station/${record.ro_number}`}>view</Link>
        );
      }
    }
  ];

  React.useEffect(() => {
    loadInitialData();
  }, [pageControl]);

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    const formValues = searchForm.getFieldsValue();
    let params = { ...formValues, offset, limit: pageControl?.pageSize, status: 'READY_TO_SHIP', channel_id: 'shopify' };
    if (formValues.date_range) {
      let sign_up_date_from = formValues.date_range[0].format('YYYY-MM-DD');
      let sign_up_date_to = formValues.date_range[1].format('YYYY-MM-DD');
      delete params.date_range;
      params = { ...params, sign_up_date_from, sign_up_date_to };
    }
    const filteredParams = objectHelpers.deleteUndefinedValuesFromObject(params);

    const { data, errors } = await orderService.fetchRoList(filteredParams);
    if (_.isEmpty(errors)) {
      const roResponse: any = data;
      const updatedRoList = roResponse?.releaseorder_list?.map((ro: any) => ({ ...ro, key: ro?.ro_number }));

      setOrderListing({ ...roResponse, releaseorder_list: updatedRoList });
      console.log('data', { ...roResponse, releaseorder_list: updatedRoList });
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const loadInitialData = async () => {
    await handleSearch(pageControl?.currentPage - 1);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Form layout="vertical" form={searchForm} onFinish={() => {}}>
          <Card>
            <Row justify={'space-between'} className="mb-4" gutter={12}>
              <Col xs={12} md={6}>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  Ready To Ship Orders
                </Typography.Title>
              </Col>
            </Row>
            <TenantInfo />
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={6}>
                <Form.Item name="burn_template_type" label={t('orderStatusM')}>
                  <Input placeholder={t('orderStatusM')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('releaseOrder')}>
                  <Input placeholder={t('releaseOrder')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('itemID')}>
                  <Input placeholder={t('itemID')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="shipping_method" label={t('orderNumber')}>
                  <Input placeholder={t('orderNumber')} size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6}>
                <Form.Item name="order_date_range" label={t('orderDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>{' '}
              <Col xs={24} md={6}>
                <Form.Item name="release_date_range" label={t('releaseDateRange')}>
                  <DatePicker.RangePicker
                    size="large"
                    placeholder={[t('startDate'), t('endDate')]}
                    style={{ borderRadius: '0' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="pb-4" gutter={12}>
              <Col xs={12} md={3}>
                <Button type="primary" htmlType="submit" size="large" block>
                  <BoldButtonLabel labelText={t('search')}></BoldButtonLabel>
                </Button>
              </Col>
              <Col xs={12} md={3}>
                <Button size="large" onClick={() => {}} block>
                  <BoldButtonLabel labelText={t('clear')} />
                </Button>
              </Col>
            </Row>
            <section>
              <Table
                loading={false}
                className="mt-4"
                pagination={{
                  current: pageControl?.currentPage,
                  total: orderListing?.page_info?.total_pages * pageControl?.pageSize || 0,
                  pageSize: pageControl?.pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['1', '10', '20', '50', '100'],

                  onChange: (currentPage, pageSize) =>
                    setPageControl({
                      currentPage,
                      pageSize
                    })
                }}
                locale={{
                  emptyText: t('noData')
                }}
                bordered
                dataSource={orderListing?.releaseorder_list || []}
                columns={columns}
                scroll={{ x: 1000 }}
              />
            </section>
          </Card>
        </Form>
      </div>
    </PrimaryLayout>
  );
};
export default ReadyToShipOrders;
